import { store } from "../app/store";
import { IRoomCategory } from "../features/booking/bookingInterface";
import {
  IHotelRoomCategory,
  IHotelRoomCategoryAvailability,
  IHotelRoomOccupancyAvailability,
} from "../features/hotel/hotelInterface";


/**
 * Get room availability by room ctegory id
 * @param adultCount
 * @param childCount
 * @param roomCategoryAvailability
 * @returns IRoomCategory | null
 */
export const getRoomCategoryAvailability = (
  adultCount: number | null,
  childCount: number | null,
  roomCategoryAvailability: IHotelRoomCategoryAvailability
): IRoomCategory | null => {
  const availabilities = filterRoomOccupancyAvailabilitiesByOccupancy(
    adultCount,
    childCount,
    roomCategoryAvailability.RoomOccupancyAvailabilities
  );

  if (availabilities.length) {
    const standardRoom = availabilities[0];
    const pricing = standardRoom.Pricing[0];
    return {
      RoomCategoryId: roomCategoryAvailability.RoomCategoryId,
      Pricing: pricing,
    };
  }
  return null;
};

/**
 * Get room availability with pricing
 * @param roomCategoryId
 * @param adultCount
 * @param childCount
 * @param roomCategoryAvailabilities
 * @returns IRoomCategory | null
 */
export const getRoomCategory = (
  roomCategoryId: string,
  adultCount: number | null,
  childCount: number | null
): IRoomCategory | null => {
  // Find category to get availability and price
  const roomCategoryAvailability = findRoomCategoryAvailability(
    roomCategoryId
  );
  if (roomCategoryAvailability) {
    return getRoomCategoryAvailability(adultCount, childCount, roomCategoryAvailability);
  } else {
    return null;
  }
};

/**
 * Find availability by category id
 * @param roomCategoryAvailabilities
 * @param roomCategoryId
 * @returns IHotelRoomCategoryAvailability | undefined
 */
export const findRoomCategoryAvailability = (
  roomCategoryId: string
): IHotelRoomCategoryAvailability | undefined => {
  const { hotel } = store.getState();
  return hotel.RoomCategoryAvailabilities.find((r) => r.RoomCategoryId === roomCategoryId);
};

/**
 * Get availability bij occupancy
 * @param adultCount
 * @param childCount
 * @param roomOccupancyAvailabilities
 * @returns IHotelRoomOccupancyAvailability[]
 */
export const filterRoomOccupancyAvailabilitiesByOccupancy = (
  adultCount: number | null,
  childCount: number | null,
  roomOccupancyAvailabilities: IHotelRoomOccupancyAvailability[]
): IHotelRoomOccupancyAvailability[] => {
  return roomOccupancyAvailabilities.filter((occupany) => {
    // Check on adult and child
    if (adultCount !== null && childCount !== null) {
      return occupany.AdultCount === adultCount && occupany.ChildCount === childCount;
    }

    // Check on adult only
    else if (adultCount !== null && childCount === null) {
      return occupany.AdultCount === adultCount;
    }

    // Check on child only
    else if (adultCount === null && childCount !== null) {
      return occupany.ChildCount === childCount;
    }

    return true;
  });
};


/**
 * Find availability by category id
 * @param roomCategoryId
 * @returns number
 */
export const getRoomAvailability = (Id: IHotelRoomCategory["Id"]): number => {
  const { hotel, booking } = store.getState();
  let count = 0;

  // Check if availibility exists for room category
  const category = hotel.RoomCategoryAvailabilities.find((r) => r.RoomCategoryId === Id);
  if (category) {
    count = category.AvailableRoomCount;

    // Minus already booked
    const reservationCount = booking.Reservations.filter((r) => r.RoomCategoryId === Id).length;
    count = count - reservationCount;
  }

  return count;
};
