import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  createBookingReservationGroup,
  selectBooking,
  setBookingCustomer,
} from "../features/booking/bookingSlice";
import { IbookingCustomer } from "../features/booking/bookingInterface";
import { countries } from "../helpers/countries";
import InputField from "../components/Form/InputField";
import SelectField from "../components/Form/SelectField";
import Textarea from "../components/Form/Textarea";
import CheckBoxField from "../components/Form/CheckBoxField";
import { useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import UspBar from "../components/UspBar";
import { GTMTrackingSelectPage } from "../helpers/GTMTracking";
import PhoneField from "../components/Form/PhoneField";
import { selectConfig, setReturnUrl } from "../features/config/configSlice";
import { openUrl } from "../helpers/payment";
import ContentOverlay from "../components/ContentOverlay";
import ArrowSmall from "../components/Svg/ArrowSmall";
import Button from "../components/Button";

function Details() {
  const dispatch = useAppDispatch();
  const booking = useAppSelector(selectBooking);
  const config = useAppSelector(selectConfig);
  const navigate = useNavigate();
  const location = useLocation();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [overlayContent, setOverlayContent] = useState<string>("");
  const countryOptions: { [key: string]: string } = countries();
  const InitialCustomerState = {
    Email: "",
    FirstName: "",
    LastName: "",
    Telephone: "",
    AddressLine1: "",
    AddressLine2: "",
    City: "",
    PostalCode: "",
    StateCode: "",
    NationalityCode: "NL",
    Notes: "",
    SendMarketingEmails: false,
    Terms: false,
  };
  const [customerState, setCustomerState] = useState<IbookingCustomer>(
    booking.Customer ?? InitialCustomerState
  );
  const initialValidState = {
    FirstName: null,
    LastName: null,
    Telephone: null,
    Email: null,
    NationalityCode: null,
    Terms: null,
  };
  const [validationState, setValidationState] = useState<{ [key: string]: boolean | null }>(
    initialValidState
  );

  useEffect(() => {
    dispatch(setBookingCustomer(customerState));
  }, [customerState, dispatch]);

  useEffect(() => {
    let isValid = true;
    Object.keys(validationState).forEach((name) => {
      if (validationState[name] === null || validationState[name] === false) {
        isValid = false;
      }
    });
    setIsValid(isValid);
  }, [validationState]);

  const next = () => {
    if (validateCustomer()) {
      // GTM DATALAYER
      GTMTrackingSelectPage(location.pathname);
      navigate("/payment");
    }
  };

  const nextCreateReservation = async () => {
    if (validateCustomer()) {
      setOverlayContent(`Creating your reservation.`);
      setShowOverlay(true);

      await dispatch(createBookingReservationGroup({ data: null }))
        .then((response) => {
          if (response.payload.Reservations.length > 0) {
            setOverlayContent(`Reservation created`);
            setTimeout(() => {
              navigate("/success");
            }, 1000);
          } else {
            setOverlayContent(`Something went wrong, please try again.`);
            setTimeout(() => {
              setShowOverlay(false);
            }, 2000);
          }
        })
        .catch((response) => {
          setOverlayContent(`[error:4501] Something went wrong, please try again.`);
          setTimeout(() => {
            setShowOverlay(false);
          }, 2000);
        });
    }
  };

  // Create reservation group and return to return url
  const nextReturnUrl = async () => {
    if (validateCustomer() && config.ReturnUrl !== null) {
      setOverlayContent(`Creating your reservation.`);
      setShowOverlay(true);

      await dispatch(createBookingReservationGroup({ data: null }))
        .then((response) => {
          if (response.payload.Reservations.length > 0) {
            const returnUrl = config.ReturnUrl;
            dispatch(setReturnUrl(null));
            setOverlayContent(`Reservation created`);
            setTimeout(() => {
              openUrl(returnUrl + "?reservationId=" + response.payload.Reservations[0].Id ?? "");
              setOverlayContent(`Finish your payment at our kiosk.`);
              return false;
            }, 1000);
          } else {
            setOverlayContent(`Something went wrong, please try again.`);
            setTimeout(() => {
              setShowOverlay(false);
            }, 2000);
          }
        })
        .catch((response) => {
          setOverlayContent(`[error:4501] Something went wrong, please try again.`);
          setTimeout(() => {
            setShowOverlay(false);
          }, 2000);
        });
    }
  };

  const handleValueCallback = (obj: { [key: string]: string | boolean }) => {
    setCustomerState((previousState) => ({ ...previousState, ...obj }));
  };

  const handleValidateCallBack = (obj: { [key: string]: boolean | null }) => {
    setValidationState((previousState) => ({ ...previousState, ...obj }));
  };

  const validateCustomer = () => {
    let isValid = true;
    let obj: { [key: string]: boolean | null } = {};
    Object.keys(validationState).forEach((name) => {
      if (validationState[name] === null) {
        obj[name as keyof typeof obj] = false;
        isValid = false;
      } else if (validationState[name] === false) {
        isValid = false;
      }
    });

    setValidationState({ ...validationState, ...obj });

    if (!isValid) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className="absolute flex flex-col w-full h-full">
        <div className="flex-grow relative w-full">
          <motion.div
            initial={{ opacity: 0, y: "-12px" }}
            animate={{ opacity: 1, y: "0px" }}
            exit={{ opacity: 0, y: "-12px" }}
            className="absolute inset-0 overflow-y-auto w-full px-4 py-2 pb-4"
          >
            <div className="flex flex-col space-y-4 text-medium">
              <InputField
                type="text"
                label="First name"
                name="FirstName"
                value={customerState.FirstName}
                error={validationState.FirstName}
                validate={{ min: 1 }}
                errorMessages={{ min: "First name is required" }}
                valueCallback={handleValueCallback}
                validationCallBack={handleValidateCallBack}
              />

              <InputField
                type="text"
                label="Last name"
                name="LastName"
                value={customerState.LastName}
                error={validationState.LastName}
                validate={{ min: 1 }}
                errorMessages={{ min: "Last name is required" }}
                valueCallback={handleValueCallback}
                validationCallBack={handleValidateCallBack}
              />

              <InputField
                type="email"
                label="Email address"
                name="Email"
                value={customerState.Email}
                error={validationState.Email}
                validate={{ min: 1, email: "" }}
                errorMessages={{
                  min: "Email address is required",
                  email: "Email is required and should contain a @ and at least one dot",
                }}
                valueCallback={handleValueCallback}
                validationCallBack={handleValidateCallBack}
              />

              <PhoneField
                label="Phone number"
                name="Telephone"
                value={customerState.Telephone}
                error={validationState.Telephone}
                validate={{ min: 9, phonenumber: true }}
                errorMessages={{
                  min: "Phone number should be at least 9 numbers",
                  phonenumber: "Phone number should be numeric",
                }}
                valueCallback={handleValueCallback}
                validationCallBack={handleValidateCallBack}
              />

              <SelectField
                label="Country"
                name="NationalityCode"
                value={customerState.NationalityCode}
                error={validationState.NationalityCode}
                options={countryOptions}
                validate={{ required: true }}
                errorMessages={{ required: "Country is a required field" }}
                valueCallback={handleValueCallback}
                validationCallBack={handleValidateCallBack}
              />

              <Textarea
                label="Special requests"
                name="Notes"
                value={customerState.Notes}
                callback={handleValueCallback}
              />

              <CheckBoxField
                label={
                  <>
                    I agree to{" "}
                    <a
                      href="https://cityhub.com/terms-conditions/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Property T&C
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://cityhub.com/privacy-statement/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Property Privacy Policy
                    </a>
                    .*
                  </>
                }
                name="Terms"
                value={customerState.Terms}
                error={validationState.Terms}
                validate={{ required: true }}
                valueCallback={handleValueCallback}
                validationCallBack={handleValidateCallBack}
              />

              <CheckBoxField
                label={<>I'd like to occasionally receive marketing updates.</>}
                name="SendMarketingEmails"
                value={customerState.SendMarketingEmails}
                error={validationState.SendMarketingEmails}
                validate={{}}
                valueCallback={handleValueCallback}
                validationCallBack={handleValidateCallBack}
              />
            </div>
          </motion.div>
        </div>

        <div className={`w-full bg-white rounded-t-lg p-2 relative`}>
          <UspBar />

          {booking.Prices.Accommodation === 0 && booking.Prices.Extras === 0 && (
            <div className="mt-2">
              <Button
                text="Create reservation"
                onClick={() => nextCreateReservation()}
                suffix={<ArrowSmall className="h-4 w-4" />}
                disabled={!isValid}
              />
            </div>
          )}

          {(booking.Prices.Accommodation > 0 || booking.Prices.Extras > 0) && (
            <>
              {(config.ReturnUrl === null || config.ReturnUrl === "") && (
                <div className="mt-2">
                  <Button
                    text="Payment"
                    onClick={() => next()}
                    suffix={<ArrowSmall className="h-4 w-4" />}
                    disabled={!isValid}
                  />
                </div>
              )}

              {config.ReturnUrl !== null && config.ReturnUrl !== "" && (
                // Create reservation and return to Return url
                // Payment is done in kiosk or other application
                <div className="mt-2">
                  <Button
                    text="Payment"
                    onClick={() => nextReturnUrl()}
                    suffix={<ArrowSmall className="h-4 w-4" />}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ContentOverlay show={showOverlay} content={overlayContent} />
    </>
  );
}

export default Details;
