import React from 'react'

export default function SecureField({ fieldType, label, customClass, callback, children }) {
  
  return (
    <div id={fieldType + '-number-container'} className={`c-form__element c-form__element--active ${customClass}`} onClick={() => callback()}>
      <label htmlFor={fieldType + '-number'}>{label}</label>
      <div className="flex items-center gap-2 bg-white rounded-md p-2 border mt-1 h-12">
        <div className="secure-field--input" id={fieldType + '-number'}></div>
      <div className="relative">{children}</div>
      </div>
    </div>
  );
}
