import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { IOverlayState } from "./infoOverlayInterface";

const initialState: IOverlayState = {
  RoomCategoryId: "",
};

export const infoOverlaySlice = createSlice({
  name: "infoOverlay",
  initialState,
  reducers: {
    setInfoOverlayRoomCategoryId: (
      state,
      action: PayloadAction<IOverlayState["RoomCategoryId"]>
    ) => {
      state.RoomCategoryId = action.payload;
    },
    removeInfoOverlayRoomCategoryId: (state, action: PayloadAction) => {
      state.RoomCategoryId = "";
    },
  },
});

export const { setInfoOverlayRoomCategoryId, removeInfoOverlayRoomCategoryId } =
  infoOverlaySlice.actions;

export const selectInfoOverlay = (state: RootState) => state.infoOverlay;

export default infoOverlaySlice.reducer;
