import { createRef, useEffect, useRef, useState } from "react";

interface IInputField {
  type: string;
  label: string;
  name: string;
  value: string;
  error: boolean | null;
  validate: { [key: string]: string | number | boolean };
  errorMessages: { [key: string]: string };
  valueCallback: (obj: { [key: string]: string }) => void;
  validationCallBack: (obj: { [key: string]: boolean | null }) => void;
}

function MonthField(props: IInputField) {
  const {
    type,
    label,
    name,
    value,
    error,
    validate,
    errorMessages,
    valueCallback,
    validationCallBack,
  } = props;

  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const monthInput = createRef<HTMLInputElement>();
  const yearInput = createRef<HTMLInputElement>();

  const [active, setActive] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isInValid, setIsInValid] = useState<string | null>(null);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (value !== "") {
      setActive(true);
    }

    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (value !== "") {
        handleBlur();
      }
    } else {
      handleBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, error]);

  const handleFocus = () => {
    setActive(true);
    if (!active) {
      monthInput.current?.focus();
    }
  };

  const handleBlur = () => {
    setIsValid(false);
    setIsInValid(null);
    let valid = null;
    Object.keys(validate).forEach((err) => {
      // Validate min
      if (err === "min" && value?.length < Number(validate[err])) {
        valid = "min";
      }
      // Validate numeric
      else if (err === "expire") {
        // Check if only numbers
        const expirationDate = value.split("-");

        if (
          expirationDate.length > 1 &&
          expirationDate[0].match(/^[0-9]+$/) !== null &&
          expirationDate[1].match(/^[0-9]+$/) !== null
        ) {
          // Check year
          const date = new Date();
          if (parseInt(expirationDate[0]) < date.getFullYear()) {
            valid = "yearMin";
          }
          // if (parseInt(expirationDate[0]) > date.getFullYear() + 5) {
          //   valid = "yearMax";
          // }
          if (parseInt(expirationDate[1]) > 12) {
            valid = "monthMax";
          }
          if (
            parseInt(expirationDate[0]) === date.getFullYear() &&
            parseInt(expirationDate[1]) < date.getMonth() + 1
          ) {
            valid = "monthMin";
          }
        } else {
          valid = "expire";
        }
      }
    });

    let obj: { [key: string]: boolean | null } = {};
    if (valid === null) {
      setIsValid(true);
      obj[name as keyof typeof obj] = true;
      validationCallBack(obj);
    } else {
      obj[name as keyof typeof obj] = false;
      validationCallBack(obj);
      setIsInValid(valid);
    }
  };

  const handleInput = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    let value: string = "";
    if (yearInput.current) {
      setYear(yearInput.current.value);
      value += yearInput.current.value + "-";
    }
    if (monthInput.current) {
      setMonth(monthInput.current.value);
      value += monthInput.current.value;
    }
    let obj: { [key: string]: string } = {};
    obj[e.currentTarget.name as keyof typeof obj] = value;
    valueCallback(obj);
  };

  return (
    <div onClick={() => handleFocus()}>
      <label htmlFor="">{label}</label>
      <div
        className={`flex items-center gap-2 bg-white rounded-md p-2 border mt-1 ${
          error === false ? "border-red-600" : isValid ? "" : "border-white"
        }`}
      >
        <input
          ref={monthInput}
          name={name}
          type={type}
          value={month}
          placeholder="Month (E.g. 05)"
          maxLength={2}
          onChange={handleInput}
          onFocus={() => handleFocus()}
          onBlur={() => handleBlur()}
          className="w-full border-transparent focus:border-transparent focus:ring-0 p-0"
        />
        <span>/</span>
        <input
          ref={yearInput}
          name={name}
          type={type}
          value={year}
          placeholder="Year (E.g. 2029)"
          maxLength={4}
          onChange={handleInput}
          onFocus={() => handleFocus()}
          onBlur={() => handleBlur()}
          className="w-full border-transparent focus:border-transparent focus:ring-0 p-0"
        />
      </div>

      {error === false && errorMessages && (
        <div className="text-small text-red-600 text-right mt-1">
          {Object.keys(errorMessages).map((err, i) => {
            if (err === isInValid) {
              return <div key={i}>{errorMessages[err]}</div>;
            } else {
              return "";
            }
          })}
        </div>
      )}
    </div>
  );
}

export default MonthField;
