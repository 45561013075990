import { useAppSelector } from "../app/hooks";
import { useRef } from "react";
import { selectHotel } from "../features/hotel/hotelSlice";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperCore } from "swiper/types";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ArrowSmall from "./Svg/ArrowSmall";

interface ICardSlider {
  images: string[];
}

export default function CardSlider({ images }: ICardSlider) {
  const hotel = useAppSelector(selectHotel);
  const swiperRef = useRef<SwiperCore>();

  return (
    <div className="swiper">
      <Swiper
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + ' border border-gray-600"></span>';
          },
        }}
        modules={[Pagination, Navigation]}
        navigation={{
          nextEl: ".swiper-arrow-next",
          prevEl: ".swiper-arrow-prev",
          disabledClass: "opacity-50 cursor-not-allowed",
        }}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        updateOnWindowResize
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
      >
        {images.map((img, i) => (
          <SwiperSlide key={i}>
            <img
              src={`${hotel.ImageBaseUrl}/${img}?Mode=0&Height=300&Width=600`}
              alt={`Slider ${i}`}
            />
          </SwiperSlide>
        ))}
        <div className="swiper-pagination flex justify-center gap-col-2"></div>
        <div className="swiper-arrow-prev absolute top-0 left-0 h-full w-14 z-30 flex items-center justify-center cursor-pointer">
          <ArrowSmall className="text-white w-8 h-8 rotate-180 translate-y-1/2" />
        </div>
        <div className="swiper-arrow-next absolute top-0 right-0 h-full w-14 z-30 flex items-center justify-center cursor-pointer">
          <ArrowSmall className="text-white w-8 h-8 translate-y-1/2" />
        </div>
      </Swiper>
    </div>
  );
}
