import moment, { Moment } from 'moment';

export interface IDay {
  Date: string;
  Number: string;
  Weekday: number;
  LastDay: boolean;
}

export interface IMonth {
  Year: number;
  Month: number;
  Name: string;
  Days: IDay[];
}


export const isBefore = (date: Date, compareDate: Date) => {
  return date < compareDate;
};

export const validEndDate = (date: string, endDate: string) => {
  return date <= endDate;
};

export const datesBetween = (startDate: string, endDate: string) => {
  let dates = [];
  let currentDate = moment(startDate).utc();
  const tillDate = moment(endDate).utc();
  while (currentDate <= tillDate) {
    dates.push(getIsoString(currentDate));
    currentDate.add(1, "days");
  }
  return dates;
};


export const monthName = (month: number, lang: string = "en-US") => {
  const translations = monthTranslations(lang);
  return translations[month];
};

export const monthTranslations = (lang: string) => {
  const translations: { [key: string]: string[] } = {
    "en-US": [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  };
  return translations[lang];
};

export const getMonths = (startDate: Moment, endDate: Moment) => {
  const months: any = [];
  for (var m = startDate; m.isSameOrBefore(endDate); m.add(0, 'M')) {
    const month = {
      Year: m.year(),
      Month: m.month(),
      Name: monthName(m.month()),
      Days: getDays(m),
    };
    months.push(month);
  }
  return months;
};

export const getIsoString = (date: Moment) => {
  return  date.format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
}

export const getDays = (start: Moment) => {
  const days: IDay[] = [];
  let end = start.clone();
  end = end.endOf("month").startOf("day");
  for (let d = start; d.isSameOrBefore(end); d.add(1, 'd')) {
    const day: IDay = {
      Date: getIsoString(d),
      Number: d.format("DD"),
      Weekday: d.day(),
      LastDay: d.format() === end.format()
    };
    days.push(day);
  }
  return days;
};

export const dateToMonthSmall = (date: string | null) => {
  if(date){
    return moment(date).utc().format("MMM D");
  }
  return "";
}
