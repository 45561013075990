import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import bookingReducer from '../features/booking/bookingSlice';
import hotelReducer from '../features/hotel/hotelSlice';
import sceneReducer from '../features/scene/sceneSlice';
import infoOverlayReducer from '../features/infoOverlay/infoOverlaySlice';
import configReducer from '../features/config/configSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER, } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
}

const reducers = combineReducers({
  booking: bookingReducer,
  hotel: hotelReducer,
  scene: sceneReducer,
  infoOverlay: infoOverlayReducer,
  config: configReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);


export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // middleware: [thunk]
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
