import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import InformationCircle from "./Svg/InformationCircle";

export default function UspBar() {
  return (
    <div className="flex justify-center">
      <div className="flex items-center space-x-2 text-small text-green-600">
        <p>Cancel for free - Lowest Price Guaranteed</p>
        <Tippy
          content={
            <span>
              When you book directly with us, you can always cancel your booking for free, until 24
              hours before your arrival date.
              <br />
              We guarantee that if you are booking via this website, you will always get the best
              price possible.
            </span>
          }
        >
          <div>
            <InformationCircle className="h-5 w-5" />
          </div>
        </Tippy>
      </div>
    </div>
  );
}
