import { useAppSelector } from "../app/hooks";
import { selectHotel } from "../features/hotel/hotelSlice";
import ProductCard from "../components/Extras/ProductCard";
import { GTMTrackingViewItemList } from "../helpers/GTMTracking";
import { motion } from "framer-motion";
import { useEffect } from "react";

function Extras() {
  const hotel = useAppSelector(selectHotel);

  useEffect(() => {
    GTMTrackingViewItemList();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: "-12px" }}
      animate={{ opacity: 1, y: "0px" }}
      exit={{ opacity: 0, y: "-12px" }}
      className="absolute inset-0 overflow-y-auto w-full px-4 py-2 pb-4"
    >
      <div className="grid grid-cols-1 gap-4">
        {hotel.Products &&
          hotel.Products.map((product, i) => <ProductCard key={i} Number={i} Product={product} />)}
      </div>
    </motion.div>
  );
}

export default Extras;
