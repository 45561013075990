import axios from "axios";
import { IHotelState } from "./hotelInterface";
import { IBookingState, IBookingReservation } from "../booking/bookingInterface";

/**
 * Get hotel configuration to set room categories and products
 * 
 * @param Id
 * @param ConfigurationId
 * @returns 
 */
export function fetchHotelConfiguration (Id: IHotelState["Id"]) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_MEWS_API_URL + 'hotels/get',
    data: {
      Client: process.env.REACT_APP_MEWS_API_CLIENT,
      HotelId: Id,
    }
  })
}

/**
 * Get hotel payment configuration to set room categories and products
 * 
 * @param Id
 * @param ConfigurationId
 * @returns 
 */
export function fetchHotelPaymentConfiguration (Id: IHotelState["Id"]) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_MEWS_API_URL + 'hotels/getPaymentConfiguration',
    data: {
      Client: process.env.REACT_APP_MEWS_API_CLIENT,
      HotelId: Id,
    }
  })
}

/**
 * Get room availability, occupancy and prices by date
 * @param Id 
 * @param StartUtc 
 * @param EndUtc 
 * @returns 
 */
export function fetchHotelRoomAvailability(
  Hotel: IHotelState,
  Booking: IBookingState,
  StartUtc: IBookingState["StartUtc"],
  EndUtc: IBookingState["EndUtc"],
  VoucherCode: IBookingState["VoucherCode"],
) {
  return axios({
    method: "post",
    url: process.env.REACT_APP_MEWS_API_URL + "hotels/getAvailability",
    data: {
      Client: process.env.REACT_APP_MEWS_API_CLIENT,
      ConfigurationId: Hotel.ConfigurationId,
      HotelId: Hotel.Id,
      StartUtc,
      EndUtc,
      CurrencyCode: Hotel.DefaultCurrencyCode,
      VoucherCode: VoucherCode,
    },
  });
}


// Get Reservation price
export const getReservationPrice = (
  ConfigurationId: IHotelState["ConfigurationId"],
  CurrencyCode: IHotelState["DefaultCurrencyCode"],
  Reservations: IBookingReservation[],
) => {
  return axios({
    method: "post",
    url: process.env.REACT_APP_MEWS_API_URL + "reservations/price",
    data: {
      Client: process.env.REACT_APP_MEWS_API_CLIENT,
      ConfigurationId: ConfigurationId,
      CurrencyCode,
      Reservations
    },
  });
}


// Get configuration
export const fetchConfiguration = (
  ConfigurationId: IHotelState["ConfigurationId"],
) => {
  return axios({
    method: "post",
    url: process.env.REACT_APP_MEWS_API_URL + "configuration/get",
    data: {
      Client: process.env.REACT_APP_MEWS_API_CLIENT,
      Ids: [ConfigurationId],
      PrimaryId: ConfigurationId,
    },
  });
}


// Get current host
export const getCurrentHost = (
  HotelCode: IHotelState["HotelCode"],
) => {
  return axios.get("https://mobile-api.cityhub.com/api/v1/host/current?HotelCode=" + HotelCode);
}
