import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  IBookingReservation,
  IBookingState,
  IBookingProduct,
  IBookingReservationPrice,
  IBookingCreditCardData,
  IbookingPayment,
  IBookingRoom,
  IBookingPrices,
} from "./bookingInterface";
import { IHotelState } from "../hotel/hotelInterface";
import {
  createReservationGroup,
  getReservationGroup,
  getReservationPrice,
  validateVoucherCode,
} from "./bookingApi";

const initialState: IBookingState = {
  Status: "idle",
  CartCollapsed: false,
  Error: null,
  ErrorText: "Not all dates of your selection are available",
  StartUtc: null,
  EndUtc: null,
  Dates: [],
  NightCount: 0,
  AdultCount: 2,
  ChildCount: 0,
  PeopleCount: 0,
  Rooms: [],
  Reservations: [],
  OpenReservation: 0,
  ReservationPrice: { GrossValue: 0, NetValue: 0, TaxValue: 0, Products: {}, State: "show" },
  Products: [],
  VoucherCode: null,
  Prices: {
    Summary: [],
    Accommodation: 0,
    Extras: 0,
  },
  RateGroupId: "",
  Payment: {
    ReservationGroupId: null,
    CustomerId: "",
    PaymentRequestId: null,
    PaymentCardId: null,
    State: "idle",
    QrCode: "",
  },
};

// Update reservation when products change
const _updateReservation = (Products: IBookingProduct[], Reservations: IBookingReservation[]) => {
  const products = [...Products];
  let reservations = [...Reservations];
  return reservations.map((r) => {
    r.ProductIds = products.map((p) => p.Id);
    return r;
  });
};

// Update reservation when products change
const _updateVoucherCode = (
  VoucherCode: IBookingReservation["VoucherCode"],
  Reservations: IBookingReservation[]
) => {
  let reservations = [...Reservations];
  return reservations.map((r) => {
    r.VoucherCode = VoucherCode;
    return r;
  });
};

// Create reservation
export const createBookingReservationGroup = createAsyncThunk(
  "booking/createBookingReservationGroup",
  async ({ data = null }: { data: IBookingCreditCardData | null }, { getState }) => {
    const { hotel, booking }: { hotel: IHotelState; booking: IBookingState } = getState() as any;
    const response = await createReservationGroup(hotel, booking, data);
    return response.data;
  }
);

// Get reservation
export const getBookingReservationGroup = createAsyncThunk(
  "booking/getBookingReservationGroup",
  async (dispatch, { getState }) => {
    const { hotel, booking }: { hotel: IHotelState; booking: IBookingState } = getState() as any;
    const response = await getReservationGroup(hotel, booking);
    return response.data;
  }
);

// Get reservation price
export const getBookingReservationPrice = createAsyncThunk(
  "booking/getBookingReservationPrice",
  async (dispatch, { getState }) => {
    const { hotel, booking }: { hotel: IHotelState; booking: IBookingState } = getState() as any;
    const response = await getReservationPrice(hotel, booking);
    return response.data;
  }
);

// Validate voucher
export const validateBookingVoucherCode = createAsyncThunk(
  "booking/validateBookingVoucherCode",
  async (dispatch, { getState }) => {
    const { hotel, booking }: { hotel: IHotelState; booking: IBookingState } = getState() as any;
    const response = await validateVoucherCode(hotel, booking.VoucherCode);
    return response.data;
  }
);

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    clearBooking: (state) => {
      state.Error = null;
      state.StartUtc = null;
      state.EndUtc = null;
      state.NightCount = 0;
      state.PeopleCount = 0;
      state.Dates = [];
      state.Rooms = [];
      state.Reservations = [];
      state.Prices = {
        Summary: [],
        Accommodation: 0,
        Extras: 0,
      };
      state.Products = [];
      state.RateGroupId = "";
      state.VoucherCode = null;
      state.ReservationPrice = {
        GrossValue: 0,
        NetValue: 0,
        TaxValue: 0,
        Products: {},
        State: "show",
      };
    },
    clearBookingPayment: (state) => {
      state.Payment = {
        ReservationGroupId: null,
        CustomerId: "",
        PaymentRequestId: null,
        PaymentCardId: null,
        State: "idle",
        QrCode: "",
      };
    },
    setBookingCreditCardData: (state, action: PayloadAction<IBookingCreditCardData>) => {
      state.CreditCardData = action.payload;
    },
    toggleBookingCartCollapsed: (state, action: PayloadAction<IBookingState["CartCollapsed"]>) => {
      state.CartCollapsed = action.payload;
    },
    setBookingDates: (
      state,
      action: PayloadAction<{
        StartUtc: IBookingState["StartUtc"];
        EndUtc: IBookingState["EndUtc"];
        Dates: IBookingState["Dates"];
        NightCount: IBookingState["NightCount"];
      }>
    ) => {
      state.StartUtc = action.payload.StartUtc;
      state.EndUtc = action.payload.EndUtc;
      state.Dates = action.payload.Dates;
      state.NightCount = action.payload.NightCount;
    },
    setBookingReservation: (state, action: PayloadAction<IBookingReservation>) => {
      const Reservations = [...state.Reservations, action.payload];
      state.Reservations = Reservations;
      state.OpenReservation = Reservations.length - 1;
      // state.CartCollapsed = true;
    },
    addBookingReservations: (state, action: PayloadAction<IBookingReservation[]>) => {
      state.Reservations = action.payload;
      state.OpenReservation = 0;
    },
    updateBookingReservation: (
      state,
      action: PayloadAction<{ number: number; reservation: IBookingReservation }>
    ) => {
      const reservations = [...state.Reservations];
      reservations[action.payload.number] = action.payload.reservation;
      state.Reservations = reservations;
      // state.CartCollapsed = true;
    },
    removeBookingReservation: (state, action: PayloadAction<number>) => {
      let reservations: IBookingReservation[] = [];
      state.Reservations.forEach((r, i) => {
        if (i !== action.payload) reservations.push(r);
      });

      state.Reservations = reservations;
      state.OpenReservation = reservations.length - 1;
      // state.CartCollapsed = true;
    },
    clearBookingReservations: (state) => {
      state.Reservations = [];
      // state.CartCollapsed = false;
    },

    // Booking Rooms
    addBookingRoom: (state, action: PayloadAction<IBookingRoom>) => {
      const _rooms = [...state.Rooms, action.payload];
      _rooms.map((r, i) => {
        r.Open = _rooms.length - 1 === i;
        return r;
      });
      state.Rooms = _rooms;
    },
    updateBookingRoomOccupancy: (
      state,
      action: PayloadAction<{
        roomKey: number;
        adultCount: IBookingRoom["AdultCount"];
        childCount: IBookingRoom["ChildCount"];
      }>
    ) => {
      let _rooms: IBookingRoom[] = [...state.Rooms];
      _rooms[action.payload.roomKey].AdultCount = action.payload.adultCount;
      _rooms[action.payload.roomKey].ChildCount = action.payload.childCount;
      state.Rooms = _rooms;
    },
    updateBookingRoomCategory: (
      state,
      action: PayloadAction<{
        roomKey: number;
        roomCategoryId: IBookingRoom["RoomCategoryId"];
        adultCount: IBookingRoom["AdultCount"];
        childCount: IBookingRoom["ChildCount"];
      }>
    ) => {
      let _rooms: IBookingRoom[] = [...state.Rooms];
      _rooms[action.payload.roomKey].RoomCategoryId = action.payload.roomCategoryId;
      _rooms[action.payload.roomKey].AdultCount = action.payload.adultCount;
      _rooms[action.payload.roomKey].ChildCount = action.payload.childCount;
      state.Rooms = _rooms;
    },
    removeBookingRoom: (state, action: PayloadAction<number>) => {
      let _rooms: IBookingRoom[] = [];
      state.Rooms.forEach((r, i) => {
        if (i !== action.payload) _rooms.push(r);
      });
      if (_rooms.length > 0) {
        _rooms[0].Open = true;
      }
      state.Rooms = _rooms;
    },
    clearBookingRooms: (state) => {
      state.Rooms = [];
      // state.CartCollapsed = false;
    },

    // Booking Products
    addBookingProducts: (state, action: PayloadAction<IBookingProduct>) => {
      const products = [...state.Products, action.payload];
      state.Products = products;
      state.Reservations = _updateReservation(products, state.Reservations);
      // state.CartCollapsed = true;
    },
    removeBookingProducts: (state, action: PayloadAction<IBookingProduct["Id"]>) => {
      let products = [...state.Products];
      products = products.filter((p) => p.Id !== action.payload);
      state.Products = products;
      state.Reservations = _updateReservation(products, state.Reservations);
      // state.CartCollapsed = true;
    },
    addBookingProductDate: (
      state,
      action: PayloadAction<{ number: number; date: string; count: number }>
    ) => {
      // let products = [...state.Products];
      // const product = products[action.payload.number];
      // product.Dates.push(action.payload.date);
      // product.Dates.sort();
      // product.Count = action.payload.count * (product.Dates.length - 1);
      // products[action.payload.number] = product;
      // state.Products = products;
    },
    removeBookingProductDate: (
      state,
      action: PayloadAction<{ number: number; date: string; count: number }>
    ) => {
      // let products = [...state.Products];
      // const product = products[action.payload.number];
      // product.Dates = products[action.payload.number].Dates.filter(d => {
      //   return d !== action.payload.date
      // });
      // product.Count = action.payload.count * (product.Dates.length - 1);
      // // product.TotalPrice = product.Price * product.Count;
      //
      // products[action.payload.number] = product;
      // state.Products = products;
    },
    setOpenReservation: (state, action: PayloadAction<number>) => {
      state.OpenReservation = action.payload;
    },
    setRateReservations: (state, action: PayloadAction<string>) => {
      const reservations = [...state.Reservations].map((r) => {
        r.RateId = action.payload;
        return r;
      });
      state.Reservations = reservations;
    },
    setRateReservationsArray: (state, action: PayloadAction<string[]>) => {
      const reservations = [...state.Reservations].map((r, i) => {
        r.RateId = action.payload[i];
        return r;
      });
      state.Reservations = reservations;
    },
    setReservationRateId: (
      state,
      action: PayloadAction<{ number: number; rateId: IBookingReservation["RateId"] }>
    ) => {
      const reservations = [...state.Reservations];
      reservations[action.payload.number].RateId = action.payload.rateId;
      state.Reservations = reservations;
    },
    setBookingError: (state, action: PayloadAction<IBookingState["Error"]>) => {
      if (state.StartUtc !== null) {
        state.Error = action.payload;
      }
    },
    setBookingPeopleCount: (state, action: PayloadAction<IBookingState["PeopleCount"]>) => {
      state.PeopleCount = action.payload;
    },
    setBookingCustomer: (state, action: PayloadAction<IBookingState["Customer"]>) => {
      state.Customer = action.payload;
    },
    setBookingRateGroupId: (state, action: PayloadAction<IBookingState["RateGroupId"]>) => {
      state.RateGroupId = action.payload;
    },
    setBookingPrices: (
      state,
      action: PayloadAction<{
        summary: IBookingPrices["Summary"];
        accommodation: IBookingPrices["Accommodation"];
        extras: IBookingPrices["Extras"];
      }>
    ) => {
      state.Prices = {
        Summary: action.payload.summary,
        Accommodation: action.payload.accommodation,
        Extras: action.payload.extras,
      };
    },
    resetBookingReservationPrice: (state) => {
      state.ReservationPrice = {
        GrossValue: 0,
        NetValue: 0,
        TaxValue: 0,
        Products: {},
        State: "loading",
      };
    },
    setBookingVoucherCode: (state, action: PayloadAction<IBookingState["VoucherCode"]>) => {
      state.VoucherCode = action.payload;
      state.Reservations = _updateVoucherCode(action.payload, state.Reservations);
      return state;
    },
    removeBookingVoucherCode: (state) => {
      state.VoucherCode = null;
    },
    setBookingPaymentState: (state, action: PayloadAction<IbookingPayment["State"]>) => {
      state.Payment.State = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBookingReservationGroup.fulfilled, (state, action) => {
        state.Payment.ReservationGroupId = action.payload.Id;
        state.Payment.CustomerId = action.payload.CustomerId;
        state.Payment.PaymentRequestId = action.payload.PaymentRequestId;
        state.Payment.PaymentCardId = action.payload.PaymentCardId;

        /**
         * The QR code is basically the QR encoded reservation number (eg CHTEST12345) or
         * the QR encoded ReservationGroupId.
         */
        if (action.payload.Reservations.length === 1) {
          state.Payment.QrCode = action.payload.Reservations[0].Number;
        } else {
          state.Payment.QrCode = action.payload.Id;
        }
      })
      .addCase(getBookingReservationGroup.fulfilled, (state, action) => {
        if (action.payload.Payments.length === 0) {
          state.Payment.State = "idle";
        } else {
          state.Payment.State = action.payload.Payments.some(
            (payment: any) => payment.State === "Charged"
          )
            ? "charged"
            : "failed";
        }
      })
      .addCase(getBookingReservationPrice.pending, (state, action) => {
        state.ReservationPrice.State = "loading";
      })
      .addCase(getBookingReservationPrice.fulfilled, (state, action) => {
        const grossValue = action.payload.ReservationPrice.reduce(
          (acc: IBookingReservationPrice["GrossValue"], cur: any) => {
            return (acc += cur.TotalAmount.GrossValue);
          },
          0
        );
        const netValue = action.payload.ReservationPrice.reduce(
          (acc: IBookingReservationPrice["GrossValue"], cur: any) => {
            return (acc += cur.TotalAmount.NetValue);
          },
          0
        );
        state.ReservationPrice.GrossValue = grossValue;
        state.ReservationPrice.NetValue = netValue;
        state.ReservationPrice.TaxValue = grossValue - netValue;
        state.ReservationPrice.State = "show";

        // Get standard products like tourism fee
        let _products: { [key: string]: { [key: string]: string } } = {};
        if (action.payload.ReservationPrice.length) {
          const productOrderPrices = action.payload.ReservationPrice[0].ProductOrderPrices.filter(
            (pop: any) => !pop.ProductOptions.OfferToCustomer
          );
          productOrderPrices.forEach((item: any) => {
            if (_products[item.ProductId]) {
              item.TotalAmount.GrossValue += _products[item.ProductId].GrossValue;
            }
            _products[item.ProductId] = {
              ...item.ProductName,
              GrossValue: Number.parseFloat(item.TotalAmount.GrossValue),
            };
          });
        }
        state.ReservationPrice.Products = _products;
      })
      .addCase(getBookingReservationPrice.rejected, (state, action) => {});
  },
});

export const {
  clearBooking,
  clearBookingPayment,
  setBookingCreditCardData,
  toggleBookingCartCollapsed,
  setBookingDates,

  setBookingReservation,
  addBookingReservations,
  updateBookingReservation,
  removeBookingReservation,
  clearBookingReservations,

  addBookingRoom,
  updateBookingRoomOccupancy,
  updateBookingRoomCategory,
  removeBookingRoom,
  clearBookingRooms,

  addBookingProducts,
  removeBookingProducts,
  setOpenReservation,
  setRateReservations,
  setRateReservationsArray,
  setReservationRateId,
  setBookingError,
  addBookingProductDate,
  removeBookingProductDate,
  setBookingPeopleCount,
  setBookingCustomer,
  setBookingPrices,
  setBookingRateGroupId,
  resetBookingReservationPrice,
  setBookingVoucherCode,
  removeBookingVoucherCode,
  setBookingPaymentState,
} = bookingSlice.actions;

// Selectors
export const selectBooking = (state: RootState) => state.booking;

export default bookingSlice.reducer;
