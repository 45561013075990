import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import App from "./App";
import TagManager from 'react-gtm-module';
// import reportWebVitals from './reportWebVitals';
import "./assets/styles/globals.css";

declare var ApplePaySession: any;

declare global {
  interface Window { 
    dataLayer: any[];
    ApplePaySession: typeof ApplePaySession | undefined;
  }
}

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTMID as string
}
TagManager.initialize(tagManagerArgs);

let persistor = persistStore(store);

// Clear local storage
if (window.location.pathname === "/") {
  // console.log("Clear localstorage");
  // persistor.pause();
  // persistor.flush().then(() => {
  //   return persistor.purge();
  // });
  
}

const div = document.querySelector<HTMLElement>("#cityhub-booking-module");
if (div) {
  const root = createRoot(div);
  root.render(
    // <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
    // </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
