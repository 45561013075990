import { useEffect, useRef, useState } from "react";
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2'

interface IInputField {
  label: string;
  name: string;
  value: string;
  error: boolean | null;
  validate: { [key: string]: string | number | boolean };
  errorMessages: { [key: string]: string };
  valueCallback: (obj: { [key: string]: string }) => void;
  validationCallBack: (obj: { [key: string]: boolean | null }) => void;
}

function PhoneField(props: IInputField) {
  const {
    label,
    name,
    value,
    error,
    validate,
    errorMessages,
    valueCallback,
    validationCallBack,
  } = props;

  const [active, setActive] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isInValid, setIsInValid] = useState<string | null>(null);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (value !== "") {
      setActive(true);
    }

    if (isInitialMount.current) {
      isInitialMount.current = false;

      if (value !== "") {
        handleBlur("");
      }
    } else {
      handleBlur("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, error]);

  const handleBlur = (e: any) => {
    
    setIsValid(false);
    setIsInValid(null);
    let valid = null;
    Object.keys(validate).forEach((err) => {
      // Validate min
      if (err === "min" && value?.length < Number(validate[err])) {
        valid = "min";
      }
      // Validate numeric
      else if (err === "phonenumber") {
        // Check if only numbers
        if (
          value.length > 1 &&
          value.match(/^[0-9]+$/) !== null
        ) {
          // 
        } else {
          valid = "phonenumber";
        }
      }
    });

    let obj: { [key: string]: boolean | null } = {};
    if (valid === null) {
      setIsValid(true);
      obj[name as keyof typeof obj] = true;
      validationCallBack(obj);
    } else {
      obj[name as keyof typeof obj] = false;
      validationCallBack(obj);
      setIsInValid(valid);
    }
  };

  const handleFocus = (e: PhoneInputProps | any): void => {
    setActive(true);
  };


  const handleInput = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | any
  ) => {
    let value: string = e.phone
    let obj: { [key: string]: string } = {};
    obj[name] = value;
    valueCallback(obj);
    handleBlur("");
  };

  return (
    <div
      onClick={() => handleFocus("")}
    >
      <label htmlFor="">{label}</label>
      <div
        className={`flex items-center gap-2 bg-white rounded-md p-2 border mt-1 ${
          active ? "c-form__element--active" : ""
        } ${error === false ? "border-red-600" : isValid ? "" : "border-white"}`}
      >
      <PhoneInput
        country="nl"
        value={value}
        onChange={phone => handleInput({ phone })}
        onBlur={handleBlur}
        onFocus={handleFocus}
        enableSearch={true}
      />
      </div>

      {error === false && errorMessages && (
        <div className="text-small text-red-600 text-right mt-1">
          {Object.keys(errorMessages).map((err, i) => {
            if (err === isInValid) {
              return <div key={i}>{errorMessages[err]}</div>;
            } else {
              return "";
            }
          })}
        </div>
      )}
      
    </div>
  );
}

export default PhoneField;
