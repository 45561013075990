import { createRef, useEffect, useRef, useState } from "react";

interface ITextarea {
  label: string;
  name: string;
  value: string;
  validate?: { [key: string]: string | number | boolean };
  errorMessages?: { [key: string]: string };
  callback: (obj: { [key: string]: string }) => void;
}

function Textarea(props: ITextarea) {
  const { label, name, value, validate, errorMessages, callback } = props;
  const [isValid, setIsValid] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const inputRef = createRef<HTMLTextAreaElement>();
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      handleBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleFocus = () => {
    inputRef.current?.focus();
  };

  const handleBlur = () => {
    if (validate !== undefined) {
      setError(null);
      setIsValid(false);
      let valid = null;
      Object.keys(validate).forEach((err) => {
        // Validate min
        if (err === "min" && value?.length < Number(validate[err])) {
          valid = "min";
        }
        // Validate email
        else if (err === "email" && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
          valid = "email";
        }
        // Validate numeric
        else if (err === "numeric") {
          // Check if only numbers
          if (value.match(/^[0-9 ]+$/) === null) {
            valid = "numeric";
          }
        }
      });
      if (valid === null) {
        setIsValid(true);
      } else {
        setError(valid);
      }
    }
  };

  const handleInput = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const value: string = e.currentTarget.value;
    let obj: { [key: string]: string } = {};
    obj[e.currentTarget.name as keyof typeof obj] = value;
    callback(obj);
  };

  return (
    <div
      onClick={() => handleFocus()}
    >
      <label htmlFor="">{label}</label>
      <div
        className={`flex items-center gap-2 bg-white rounded-md p-2 border mt-1 ${error ? "border-red-600" : isValid ? "" : "border-white"}`}
      >
        <textarea
          ref={inputRef}
          name={name}
          value={value}
          onChange={handleInput}
          onFocus={() => handleFocus()}
          onBlur={() => handleBlur()}
          className="w-full border-transparent focus:border-transparent focus:ring-0 p-0"
        />
      </div>


      {error && errorMessages && (
        <div className="text-small text-red-600 text-right mt-1">
          {Object.keys(errorMessages).map((err, i) => {
            if (err === error) {
              return <div key={i}>{errorMessages[err]}</div>;
            } else {
              return "";
            }
          })}
        </div>
      )}

    </div>
  );
}

export default Textarea;
