import { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  clearBooking,
  clearBookingPayment,
  clearBookingReservations,
  resetBookingReservationPrice,
  selectBooking,
  setBookingError,
} from "../../features/booking/bookingSlice";
import { datesBetween, getIsoString, getMonths, IDay, validEndDate } from "../../helpers/date";
import { IBookingState } from "../../features/booking/bookingInterface";
import { setBookingDates } from "../../features/booking/bookingSlice";
import {
  getConfiguration,
  getHotelPaymentConfiguration,
  getRoomAvailability,
  selectHotel,
} from "../../features/hotel/hotelSlice";
import { addRoom } from "../../helpers/roomCategory";
import moment, { Moment } from "moment";
import { GTMTrackingNoDatesAvailable } from "../../helpers/GTMTracking";

function DateRange() {
  const dispatch = useAppDispatch();
  const hotel = useAppSelector(selectHotel);
  const booking = useAppSelector(selectBooking);

  const [today, setToday] = useState<any>(getIsoString(moment().utc().startOf("day")));
  const [startRange, setStartRange] = useState<Moment>(moment().utc().startOf("month"));
  const [endRange, setEndRange] = useState<Moment>(moment().utc().add(12, "M").startOf("month"));

  // const months = getMonths(startRange, endRange);
  const [months, setMonths] = useState<
    { Year: number; Month: number; Name: string; Days: IDay[] }[]
  >([]);

  /**
   * Set date range
   * Range is 12 months
   */
  useEffect(() => {
    if (process.env?.["REACT_APP_MEWS_" + hotel.HotelCode + "_CALENDAR_START"] !== undefined) {
      setToday(
        getIsoString(
          moment(process.env?.["REACT_APP_MEWS_" + hotel.HotelCode + "_CALENDAR_START"]).startOf(
            "day"
          )
        )
      );
      setStartRange(
        moment(process.env?.["REACT_APP_MEWS_" + hotel.HotelCode + "_CALENDAR_START"]).startOf(
          "month"
        )
      );
      setEndRange(
        moment(process.env?.["REACT_APP_MEWS_" + hotel.HotelCode + "_CALENDAR_START"])
          .add(12, "M")
          .startOf("month")
      );
    } else {
      setToday(getIsoString(moment().utc().startOf("day")));
      setStartRange(moment().utc().startOf("month"));
      setEndRange(moment().utc().add(12, "M").endOf("month"));
    }
  }, [hotel.HotelCode]);

  useEffect(() => {
    setMonths(getMonths(startRange, endRange));
  }, [startRange, endRange]);

  // Set state booking days and get availability
  const setDates = (StartUtc: IBookingState["StartUtc"], EndUtc: IBookingState["EndUtc"]) => {
    let Dates: IBookingState["Dates"] = [];
    let NightCount: IBookingState["NightCount"] = booking.NightCount;

    // Get available rooms from api
    if (EndUtc !== null) {
      dispatch(getRoomAvailability({ StartUtc, EndUtc, VoucherCode: null }));
    } else {
      dispatch(clearBookingReservations());
    }
    if (StartUtc !== null && EndUtc !== null) {
      Dates = datesBetween(StartUtc, EndUtc);
      NightCount = Dates.length - 1;
    }
    // Set booking dates
    dispatch(setBookingDates({ StartUtc, EndUtc, Dates, NightCount }));
    dispatch(setBookingError(null));
    dispatch(resetBookingReservationPrice());
  };

  // Get availability
  useEffect(() => {
    if (hotel.RoomCategoryAvailabilities) {
      if (hotel.RoomCategoryAvailabilities.length && booking.EndUtc !== null) {
        addRoom({ ClearRooms: true });
      }
      if (hotel.RoomCategoryAvailabilities.length === 0 && booking.EndUtc !== null) {
        dispatch(setBookingError(booking.ErrorText));
        // GTM track no dates available
        GTMTrackingNoDatesAvailable();
      }
    } else {
      dispatch(getConfiguration(hotel.ConfigurationId));
      dispatch(getHotelPaymentConfiguration(hotel.Id));
      dispatch(clearBooking());
    }
    dispatch(clearBookingPayment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel.RoomCategoryAvailabilities, dispatch]);

  // Set selected days when click on a day
  const selectDate = (date: string) => {
    if (date >= today) {
      // Start over with date range
      if (booking.StartUtc !== null && booking.EndUtc !== null) {
        setDates(date, null);
      }

      // Set start date
      else if (booking.StartUtc === null) setDates(date, null);
      // Do nothing when the same day is clicked twice
      else if (booking.StartUtc !== null && booking.StartUtc === date) {
        // Do nothing
      }

      // Set end date
      else if (booking.StartUtc !== null && validEndDate(booking.StartUtc, date)) {
        setDates(booking.StartUtc, date);
      }

      // Set new start date if date is before start date
      else if (booking.StartUtc !== null && !validEndDate(booking.StartUtc, date)) {
        setDates(date, null);
      }

      // Set first start date
      else {
        setDates(date, null);
      }

      // Reset date range
    } else {
      setDates(null, null);
    }
  };

  const classes = (day: IDay, i: number) => {
    let className = "";
    if (i === 0) {
      if (day.Weekday === 0) {
        className += " col-start-7";
      } else {
        className += " col-start-" + day.Weekday;
      }
    }

    if (day.Date < today) {
      className += " border-transparent text-gray-400 line-through pointer-events-none";
    } else if (day.Date === booking.StartUtc) {
      className += " border-black border-2 rounded-md";
      if (
        booking.EndUtc !== null &&
        booking.EndUtc !== booking.StartUtc &&
        !day.LastDay &&
        day.Weekday !== 0
      ) {
        className += " rounded-e-none border-r-0";
      }
    } else if (day.Date === booking.EndUtc) {
      className += " border-black border-2 rounded-md";
      if (day.Number !== "01" && day.Weekday !== 1) className += " rounded-s-none border-l-0";
    } else if (booking.Dates.includes(day.Date)) {
      className += " border-black border-x-0 ";

      if (day.Weekday === 0 && i === 0) className += "";
      else if (i === 0) className += "border-l-2 rounded-l-md";
      else if (day.LastDay) {
        if (day.Weekday !== 1) {
          className += "border-r-2 rounded-r-md";
        }
      } else if (day.Weekday === 1) className += "border-l-2 rounded-l-md";
      else if (day.Weekday === 0) className += "border-r-2 rounded-r-md";
    } else {
      className += " border-transparent";
    }

    if (booking.Dates.length > 1 && booking.Dates.includes(day.Date)) {
      className += " bg-white";
    }

    return className;
  };

  return (
    <div className="flex flex-col gap-y-4 w-full">
      {months.map((month, i) => {
        return (
          <div key={`month-${i}`} className="w-full bg-white bg-opacity-50 px-3 py-2 rounded-md">
            <p className="text-medium">
              {month.Name} {month.Year}
            </p>

            <div className="grid grid-cols-7 gap-y-3 w-full text-medium text-center cursor-pointer">
              {month.Days.map((day, i) => {
                return (
                  <div
                    key={`day-${i}`}
                    onClick={() => {
                      selectDate(day.Date);
                    }}
                    className={`p-1 border-2 ${classes(day, i)}`}
                  >
                    <span>{day.Number}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default DateRange;
