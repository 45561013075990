import { useAppSelector } from "../app/hooks";
import { selectBooking } from "../features/booking/bookingSlice";
import { formatCurrency } from "../helpers/currency";
import Loader from "./Loader";
import { motion } from "framer-motion";
import UspBar from "./UspBar";
import Button from "./Button";
import ArrowSmall from "./Svg/ArrowSmall";
import { dateToMonthSmall } from "../helpers/date";
import { getRoomCategoryName } from "../helpers/roomCategory";
import { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface ICartProps {
  next: () => void;
}

function Cart({ next }: ICartProps) {
  const location = useLocation();
  const booking = useAppSelector(selectBooking);
  const [open, setOpen] = useState<boolean>(false);

  useLayoutEffect(() => {
    setOpen(location.pathname === '/calendar');
  }, [location]);

  const motionTransition = { type: "tween", stiffness: 100, duration: 0.3 };
  const motionVariants = {
    open: { height: "auto", marginTop: "16px", opacity: 1, y: 0 },
    closed: { height: 0, marginTop: 0, opacity: 0, y: "4px" },
  };

  return (
    <div
      className={`w-full bg-white rounded-t-lg pt pb-2 px-2 relative `}
    >
       {booking.StartUtc !== null && booking.EndUtc !== null && booking.Error === null && (
        <div
          className="absolute top-3 left-0 z-40 w-full flex justify-center cursor-pointer"
          onClick={() => setOpen(!open)}
        >
          <span
            className={`h-1 w-4 bg-gray-400 rounded-lg transition-transform translate-x-1 ${
              open ? "rotate-[30deg]" : "-rotate-[30deg]"
            }`}
          ></span>
          <span
            className={`h-1 w-4 bg-gray-400 rounded-lg transition-transform  ${
              open ? "-rotate-[30deg]" : "rotate-[30deg]"
            }`}
          ></span>
        </div>
      )}

       <motion.div
        transition={motionTransition}
        variants={motionVariants}
        animate={booking.NightCount > 0 && open ? "open" : "closed"}
        className={`h-0 opacity-0 mb-2 ${booking.EndUtc === null ? "transparent" : ""}`}
        onClick={() =>  setOpen(!open)}
      >
      {booking.StartUtc !== null && booking.EndUtc !== null && booking.Error === null && (
        <div>
          <div className="flex justify-start">
            <div className="flex w-full">
              <span>
                {dateToMonthSmall(booking.StartUtc)} - {dateToMonthSmall(booking.EndUtc)}
              </span>
            </div>
          </div>

          <div className="flex justify-between text-medium opacity-75">
            <div className="flex items-center">
                {booking.Reservations.length === 1 && (
                  <>
                    <span>{getRoomCategoryName(booking.Reservations[0].RoomCategoryId)}</span>
                    <div className="ml-2 text-small">
                      ({booking.PeopleCount} traveller{booking.PeopleCount === 1 ? "" : "s"})
                    </div>
                  </>
                )}
                {booking.Rooms.length > 1 && <span>Accommodation</span>}
            </div>
            <div>{formatCurrency(booking.Prices.Accommodation)}</div>
          </div>

          {booking.Prices.Extras > 0 && (
            <div className="flex justify-between text-medium opacity-75">
              <div>Extras</div>
              <div>{formatCurrency(booking.Prices.Extras)}</div>
            </div>
          )}
        </div>
      )}
      </motion.div>

      <div className="bg-white relative z-30">
        <div onClick={() => setOpen(!open)}>
          {(booking.EndUtc === null || booking.Error !== null) && (
            <h4 className={`text-center ${booking.Error !== null ? "text-red-600" : "text-black"}`}>
              {booking.StartUtc === null && booking.EndUtc === null && <>Select arrival date</>}
              {booking.StartUtc !== null && booking.EndUtc === null && <>Select departure date</>}
              {booking.Error !== null && <>{booking.Error}</>}
            </h4>
          )}

          {booking.EndUtc !== null && booking.Error === null && (
            <div
              className={`flex justify-between text-medium pt-2 border-t-2 transition-all duration-500 ${open ? 'border-gray-400' : 'border-white'}`}
            >
              <div>
                Total{" "}
                <span className={`text-sm transition-opacity duration-500 ${open ? 'opacity-0' : 'opacity-50'}`}>({dateToMonthSmall(booking.StartUtc)} - {dateToMonthSmall(booking.EndUtc)})</span>
              </div>
              {booking.ReservationPrice.State === "show" && (
                <div>{formatCurrency(booking.ReservationPrice.GrossValue)} </div>
              )}
              {booking.ReservationPrice.State === "loading" && <Loader />}
            </div>
          )}
        </div>

        <UspBar />

        <div className="mt-2">
          <Button
            text="Next"
            onClick={() => next()}
            suffix={<ArrowSmall className="h-4 w-4" />}
            disabled={
              booking.Reservations.length === 0 || booking.ReservationPrice.State === "loading"
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Cart;
