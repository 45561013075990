import Hub from "../Svg/Hub";
import Room from "../Svg/Room";
import TwinHub from "../Svg/TwinHub";
import MovieHub from "../Svg/MovieHub";

export default function ReservationCardCategoryName(props: { name: string; rename?: boolean }) {
  const { name, rename = false } = props;

  const renamed: any = {
    Hub: "Hub",
    "Twin Hub": "Twin Hub",
    Room: "Quad room",
    "Movie Hub": "Movie Hub",
  };

  return (
    <div className="w-full flex items-center gap-x-1 text-center">
      <div className="flex gap-1">
        {name === "Hub" && <Hub className="h-5 w-5" />}
        {name === "Twin Hub" && <TwinHub className="h-5 w-5" />}
        {name === "Room" && (
          <>
            <Room className="w-5 h-5" /> <Room className="h-5 w-5" />
          </>
        )}
        {name === "Movie Hub" && <MovieHub className="h-5 w-5" />}
      </div>
      <span className="whitespace-nowrap">
        {!rename && name}
        {rename && renamed[name]}
      </span>
    </div>
  );
}
