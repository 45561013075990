import { store } from "../app/store";
import { IbookingPayment } from "../features/booking/bookingInterface";
import { setBookingVoucherCode } from "../features/booking/bookingSlice";


export const returnUrl = () => {
  if (window.self !== window.top) {
    return window.btoa(document.referrer + "?booking-module=redirect-payment");
  } else {
    return window.btoa(
      window.location.protocol + "//" + window.location.host + "/redirect-payment"
    );
  }
};

export const getUrl = (method: string, paymentRequestId: IbookingPayment["PaymentRequestId"]) => {
  return (
    process.env.REACT_APP_MEWS_APPLICATION_URL +
    "navigator/payment-requests/detail/" +
    paymentRequestId +
    "?paymentMethod=" +
    method +
    "&returnUrl=" +
    returnUrl()
  );
};

export const setCart = (value: string | null) => {
  store.dispatch(setBookingVoucherCode(value));
};

export const openUrl = (url: string) => {
  // In iframe
  if (window.self !== window.top) {
    setTimeout(() => {
      if(window.top){
        window.top.location.href = url;
      }
    });
  }
  // Not in iframe
  else {
    window.location.href = url;
  }
};
