import { useAppSelector } from "../app/hooks";
import { selectHotel } from "../features/hotel/hotelSlice";
import { useEffect } from "react";
import Select from "../components/SelectHotel/Select";
import { useLocation, useNavigate } from "react-router-dom";

function SelectHotel() {
  const hotel = useAppSelector(selectHotel);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(location.pathname === "/" && hotel.HotelCode !== ""){
      navigate("/calendar");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={`flex items-end justify-start p-6 h-screen w-full`}>
      <div className="text-h1 leading-none">
        <p className="text-h5 mb-4">
          Let’s book together, choose your city
        </p>
        <Select />
      </div>
    </section>
  );
}

export default SelectHotel;
