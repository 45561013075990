import { useAppSelector } from "../../app/hooks";
import { selectHotel } from "../../features/hotel/hotelSlice";

function CalendarTop() {
  const hotel = useAppSelector(selectHotel);
  return (
    <>
      <div className="px-4 pt-2 pb-2">
        <div className="grid grid-cols-7 w-full bg-white rounded-md py-2 text-medium text-center">
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
          <div>S</div>
        </div>
      </div>

      {process.env?.["REACT_APP_MEWS_" + hotel.HotelCode + "_CALENDAR_START_TEXT"] !== undefined && (
        <div className="px-4">
          {hotel.Name !== undefined && (
            <div className="rounded-md bg-green-600 text-white p-2">
              <p className="text-medium">{hotel.Name[hotel.DefaultLanguageCode]} will open in {process.env?.["REACT_APP_MEWS_" + hotel.HotelCode + "_CALENDAR_START_TEXT"]}</p>
            </div>
          )}
        </div>
      )}

    </>
  );
}
export default CalendarTop;
