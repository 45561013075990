import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectBooking, setBookingPaymentState } from "../features/booking/bookingSlice";
import { useEffect, useRef, useState } from "react";
import ContentOverlay from "../components/ContentOverlay";
import { selectHotel } from "../features/hotel/hotelSlice";
import {
  getPaymentCardsAll,
  getPaymentCardsAuthorize,
  getReservationGroup,
} from "../features/booking/bookingApi";
import { openUrl, returnUrl } from "../helpers/payment";
import { GTMTrackingPurchase } from "../helpers/GTMTracking";
import Button from "../components/Button";

export default function RedirectPayment() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hotel = useAppSelector(selectHotel);
  const booking = useAppSelector(selectBooking);
  const [overlayContent, setOverlayContent] = useState<string>("Checking your payment");
  const [showOverlay, setShowOverlay] = useState<boolean>(true);
  const didMountRef = useRef(false);

  const setBookingPaymentCharged = () => {
    if (booking.Payment.State !== "charged") {
      // GTM DATALAYER
      GTMTrackingPurchase();
    }
    dispatch(setBookingPaymentState("charged"));
  };

  const authorizeCard = () => {
    const url =
      process.env.REACT_APP_MEWS_APPLICATION_URL +
      "navigator/card-authorization/detail/" +
      booking.Payment.PaymentCardId +
      "?returnUrl=" +
      returnUrl();
    openUrl(url);
  };

  useEffect(() => {
    if (didMountRef.current) {
    } else {
      // No reservation found
      if (booking.Payment.ReservationGroupId === null) {
        setOverlayContent(`[error:4602] No reservation found`);
        setTimeout(() => {
          navigate("/calendar");
        }, 2000);
      }
      // Direct payment
      else if (booking.Payment.PaymentRequestId !== null) {
        getReservationGroup(hotel, booking)
          .then((response) => {
            if (response.data.Payments.length === 0) {
              setOverlayContent(`No payments found`);
              setTimeout(() => {
                navigate("/payment");
              }, 2000);
            } else if (response.data.Payments.some((payment: any) => payment.State === "Charged")) {
              setBookingPaymentCharged();
              setOverlayContent(`Payment successful`);
              setTimeout(() => {
                navigate("/success");
              }, 2000);
            } else {
              dispatch(setBookingPaymentState("failed"));
              setOverlayContent(`Payment not successful, try again`);
              setTimeout(() => {
                navigate("/payment");
              }, 2000);
            }
          })
          .catch(() => {
            setOverlayContent(`[error:4601] No reservation found`);
            setTimeout(() => {
              navigate("/calendar");
            }, 2000);
          });
      }
      // Credit card payment
      else if (booking.Payment.PaymentCardId !== null) {
        getPaymentCardsAll(booking.Payment.PaymentCardId).then((paymentCardResponse) => {
          const paymentCard = paymentCardResponse.data.PaymentCards.find((pc: any) => {
            return pc.Id === booking.Payment.PaymentCardId;
          });
          if (paymentCard !== null) {
            if (paymentCard.AuthorizationState === "Authorized") {
              setBookingPaymentCharged();
              setOverlayContent(`Payment authorized`);
              setTimeout(() => {
                navigate("/success");
              }, 2000);
            } else if (paymentCard.AuthorizationState === "Authorizable") {
              getPaymentCardsAuthorize(hotel, booking.Payment.PaymentCardId)
                .then((authorisationResponse) => {
                  if (authorisationResponse.data.State === "Authorized") {
                    setBookingPaymentCharged();
                    navigate("/success");
                  } else if (
                    authorisationResponse.data.State === "Pending" ||
                    authorisationResponse.data.State === "Requested"
                  ) {
                    setOverlayContent(`Authorization still pending`);
                    setTimeout(() => {
                      // const url =
                      //   process.env.REACT_APP_MEWS_APPLICATION_URL +
                      //   "navigator/card-authorization/detail/" +
                      //   booking.Payment.PaymentCardId +
                      //   "?returnUrl=" +
                      //   returnUrl();
                      // openUrl(url);
                      // setOverlayContent(`Finish your autorisation at our payment service provider`);
                      setShowOverlay(false);
                      return false;
                    }, 2000);
                  } else {
                    setOverlayContent(`Something went wrong, please try again.`);
                  }
                })
                .catch(() => {
                  setOverlayContent(`Something went wrong, please try again.`);
                });
            }
          }
        });
      }
      didMountRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.Payment, navigate]);

  return (
    <>
      {showOverlay === false && (
      <div className="flex flex-col items-center justify-center p-4 text-center">
        <div className="text-h3 mb-4">
            Your reservation is created but the authorization is still pending.<br /><br />Click here to authorize
            your card.
          </div>
          <Button 
            text="Authorize card"
            onClick={() => authorizeCard()}
            type="button"
            />
        </div>
      )}
      <ContentOverlay show={showOverlay} content={overlayContent} />
    </>
  );
}
