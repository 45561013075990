import { createRef, useEffect, useRef, useState } from "react";

interface ISelectField {
  label: string;
  name: string;
  value: string;
  error: boolean | null;
  options: { [key: string]: string };
  validate?: { [key: string]: string | number | boolean };
  errorMessages?: { [key: string]: string };
  valueCallback: (obj: { [key: string]: string }) => void;
  validationCallBack: (obj: { [key: string]: boolean | null }) => void;
}

function SelectField(props: ISelectField) {
  const {
    label,
    name,
    value,
    error,
    validate,
    options,
    errorMessages,
    valueCallback,
    validationCallBack,
  } = props;
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isInValid, setIsInValid] = useState<string | null>(null);
  const inputRef = createRef<HTMLSelectElement>();
  const isInitialMount = useRef(true);

  useEffect(() => {

    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (value !== "") {
        handleBlur();
      }
    } else {
      handleBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, error]);

  const handleBlur = () => {
    setIsValid(false);
    setIsInValid(null);
    let valid = null;
    if (validate !== undefined) {
      Object.keys(validate).forEach((err) => {
        // Validate min
        if (err === "required" && value?.length < Number(validate[err])) {
          valid = "required";
        }
      });
    }

    let obj: { [key: string]: boolean | null } = {};
    if (valid === null) {
      setIsValid(true);
      obj[name as keyof typeof obj] = true;
      validationCallBack(obj);
    } else {
      obj[name as keyof typeof obj] = false;
      validationCallBack(obj);
      setIsInValid(valid);
    }
  };

  const handleInput = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const value: string = e.currentTarget.value;
    let obj: { [key: string]: string } = {};
    obj[e.currentTarget.name as keyof typeof obj] = value;
    valueCallback(obj);
  };

  return (
    <div>
      <label htmlFor="">{label}</label>
      <div
        className={`flex items-center gap-2 bg-white rounded-md p-2 border mt-1 ${error === false ? "border-red-600" : isValid ? "" : "border-white"}`}
      >
        <select
          ref={inputRef}
          name={name}
          defaultValue={value}
          onChange={handleInput}
          onBlur={() => handleBlur()}
          className="w-full border-transparent focus:border-transparent focus:ring-0 p-0"
        >
          <option value="">Choose country</option>
          {Object.keys(options).map(function (key, i) {
            return (
              <option key={i} value={key}>
                {options[key]}
              </option>
            );
          })}
        </select>
      </div>

      {error === false && errorMessages && (
        <div className="text-small text-red-600 text-right mt-1">
          {Object.keys(errorMessages).map((err, i) => {
            if (err === isInValid) {
              return <div key={i}>{errorMessages[err]}</div>;
            } else {
              return "";
            }
          })}
        </div>
      )}
    </div>
  );
}

export default SelectField;
