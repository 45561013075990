import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../app/hooks";
import { NavLink, useLocation } from "react-router-dom";
import { selectHotel } from "../features/hotel/hotelSlice";
import Select from "./SelectHotel/Select";
import { motion } from "framer-motion";
import Chevron from "./Svg/Chevron";

function Navbar() {
  const hotel = useAppSelector(selectHotel);
  const [items, setItems] = useState<{ [key: string]: string }>({
    Calendar: "locked",
    Accommodation: "locked",
    Extras: "locked",
    Summary: "locked",
    Details: "locked",
    Payment: "locked",
  });
  const [openSelect, setOpenSelect] = useState<boolean>(false);
  const location = useLocation();
  const navbar = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let pages = ["Calendar", "Accommodation", "Extras", "Summary", "Details", "Payment"];

    /**
     * Check if there are any extras / product
     */
    if (hotel.Products.length === 0) {
      pages = pages.filter((item) => item !== "Extras");
    }

    let navItems = {};
    let unlocked = true;
    pages.forEach((page) => {
      const pathname = "/" + page.toLowerCase();
      navItems = { ...navItems, [page]: unlocked ? "unlocked" : "locked" };

      // Switch
      if (pathname === location.pathname && unlocked) {
        unlocked = false;
      }
    });
    setItems(navItems);

    const activeEl = document.querySelector<HTMLElement>(
      `.js-${location.pathname.toLowerCase().replace("/", "")}`
    );

    if (activeEl && navbar.current) {
      navbar.current.scrollTo({
        left: activeEl.offsetLeft - window.innerWidth + activeEl.offsetWidth * 1.5,
        behavior: "smooth",
      });
    }
  }, [location.pathname, hotel.Products]);

  const motionTransition = { duration: 0.5 };
  const motionVariants = {
    closed: { opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 },
    open: { opacity: 1, height: "auto", paddingTop: "16px", paddingBottom: "16px" },
  };

  return (
    <>
      <div className="w-full px-2 py-1 bg-white rounded-b-md">
        <div className="flex justify-center items-center text-small">
          {hotel.Name !== undefined && (
            <div
              className={`z-40 cursor-pointer ${openSelect ? "open" : ""}`}
              onClick={() => setOpenSelect(!openSelect)}
            >
              <div className="flex items-center opacity-50">
                <Chevron
                  className={`w-4 h-4 transition-transform ${openSelect ? "rotate-180" : ""} `}
                />
                <span>{hotel.Name[hotel.DefaultLanguageCode]}</span>
              </div>
              <motion.div
                transition={motionTransition}
                variants={motionVariants}
                animate={openSelect ? "open" : "closed"}
                className="absolute pl-4 pr-4 h-0 bg-black rounded-md shadow-md mt-2 text-h4 text-white overflow-hidden"
              >
                <Select />
              </motion.div>
            </div>
          )}
        </div>
      </div>

      <div className="w-full h-6 px-2 overflow-hidden my-2">
        <div
          ref={navbar}
          className="flex justify-between text-small overflow-x-scroll h-10 items-start"
        >
          {Object.keys(items).map((item, i) => {
            return (
              <div key={i} className={`js-${item.toLowerCase()}`}>
                {items[item] === "unlocked" && (
                  <NavLink
                    className={({ isActive }) =>
                      ` text-black mx-2  ${
                        isActive ? "border-black border-b-2 outline-offset-2 pb-1" : "text-gray-500"
                      }`
                    }
                    to={`/${item.toLowerCase()}`}
                  >
                    {item}
                  </NavLink>
                )}

                {items[item] !== "unlocked" && (
                  <div
                    className={`mx-2 ${
                      location.pathname === "/" + item.toLowerCase()
                        ? "text-black"
                        : "text-gray-500"
                    }`}
                  >
                    {item}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Navbar;
