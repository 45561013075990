import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  addBookingProducts,
  removeBookingProducts,
  selectBooking,
} from "../../features/booking/bookingSlice";
import { formatCurrency } from "../../helpers/currency";
import { hasProduct } from "../../helpers/reservation";
import { IHotelProduct } from "../../features/hotel/hotelInterface";
import { selectHotel } from "../../features/hotel/hotelSlice";
import { useEffect, useState } from "react";
import { GTMTrackingRemoveProduct, GTMTrackingSelectProduct } from "../../helpers/GTMTracking";
import Button from "../Button";
import PlusSmall from "../Svg/PlusSmall";
import MinusSmall from "../Svg/MinusSmall";

interface IProps {
  Number: number;
  Product: IHotelProduct;
}

function ProductCard({ Number, Product }: IProps) {
  const dispatch = useAppDispatch();
  const hotel = useAppSelector(selectHotel);
  const booking = useAppSelector(selectBooking);
  const [trackSelectItem, setTrackSelectItem] = useState<boolean>(false);

  const addProduct = (p: IHotelProduct) => {
    setTrackSelectItem(true);
    const product = {
      Id: p.Id,
      Count: booking.PeopleCount * booking.NightCount,
      Dates: booking.Dates,
      Price: p.Prices[hotel.DefaultCurrencyCode],
    };
    dispatch(addBookingProducts(product));
  };
  useEffect(() => {
    if (trackSelectItem) {
      // Only track last item
      GTMTrackingSelectProduct();
      setTrackSelectItem(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.Products]);

  const removeProduct = (id: IHotelProduct["Id"]) => {
    GTMTrackingRemoveProduct(id);
    dispatch(removeBookingProducts(id));
  };

  return (
    <>
      <div className="bg-white bg-opacity-75 border border-gray-200 rounded-md">
        {Product.ImageId && (
          <img
            className="rounded-t-md"
            src={`${hotel.ImageBaseUrl}/${Product.ImageId}?Mode=0&Height=240&Width=600`}
            alt={Product.Name[hotel.DefaultLanguageCode]}
          />
        )}

        <div className="p-4 space-y-2">
          <div className="text-h2 leading-none">{Product.Name[hotel.DefaultLanguageCode]}</div>
          <div className="text-medium text-left leading-none">
            {formatCurrency(Product.Prices[hotel.DefaultCurrencyCode])} per person, per night
          </div>

          <p className="text-black opacity-50 text-medium">
            {Product.Description[hotel.DefaultLanguageCode]}
          </p>

          <div>
            <div className="mt-4">
              {!hasProduct(Product.Id) && (
                <Button
                  text="Add to reservation"
                  classes="secondary"
                  size="medium"
                  onClick={() => addProduct(Product)}
                  suffix={<PlusSmall />}
                />
              )}
              {hasProduct(Product.Id) && (
                <Button
                  text="Remove"
                  size="medium"
                  classes="secondary"
                  onClick={() => removeProduct(Product.Id)}
                  suffix={<MinusSmall />}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductCard;
