import { SVGProps } from "react"

export default function ArrowSmall(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="31"
      height="28"
      viewBox="0 0 31 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
      {...props}
    >
      <path
        d="M21.0357 0.499999C21.2571 7.26174 24.9771 11.253 31 11.7696L31 16.2304C24.9771 16.747 21.2571 20.7383 21.0357 27.5L15.9429 26.3261C16.3857 21.6774 19.7071 17.3574 23.25 16.2304L9.22132e-07 16.2304L5.3215e-07 11.7696L23.25 11.7696C19.7071 10.6426 16.3857 6.32261 15.9429 1.67391L21.0357 0.499999Z"
        fill="currentColor"
      />
    </svg>
  )
}
