import axios from "axios";
import {
  IBookingCreditCardData,
  IbookingPayment,
  IBookingReservation,
  IBookingState,
} from "./bookingInterface";
import { IHotelState } from "../hotel/hotelInterface";

/**
 * Create the reservation
 * @param Hotel
 * @param Booking
 * @returns Object
 */
export function createReservationGroup(
  Hotel: IHotelState,
  Booking: IBookingState,
  CreditCardData: IBookingCreditCardData | null
) {
  // Format telephone number
  let Customer = { ...Booking.Customer };
  Customer.Telephone = Booking.Customer?.Telephone.replace("-", "");

  // Add notes to the reservation
  let Reservations: any = [];
  try {
    Booking.Reservations.forEach((reservation, i) => {
      let item = { ...reservation };
      Reservations[i] = Object.assign({}, item, { Notes: Customer.Notes });
    });
  } catch (error) {
    console.log("Error adding notes", error);
  }

  let data: any = {
    Client: process.env.REACT_APP_MEWS_API_CLIENT,
    ConfigurationId: Hotel.ConfigurationId,
    HotelId: Hotel.Id,
    Customer: Customer,
    Reservations: Reservations,
  };

  if (CreditCardData !== null) {
    data["CreditCardData"] = CreditCardData;
  }
  return axios({
    method: "post",
    url: process.env.REACT_APP_MEWS_API_URL + "reservationGroups/create",
    data,
  });
}

export function getReservationGroup(Hotel: IHotelState, Booking: IBookingState) {
  return axios({
    method: "post",
    url: process.env.REACT_APP_MEWS_API_URL + "reservationGroups/get",
    data: {
      Client: process.env.REACT_APP_MEWS_API_CLIENT,
      HotelId: Hotel.Id,
      ReservationGroupId: Booking.Payment.ReservationGroupId,
      Extent: { PaymentRequests: true, Payments: true },
    },
  });
}

export function getReservationPricing(
  hotel: IHotelState,
  booking: IBookingState,
  reservation: IBookingReservation,
  voucherCode: IBookingReservation["VoucherCode"]
) {
  return axios({
    method: "post",
    url: process.env.REACT_APP_MEWS_API_URL + "reservations/getPricing",
    data: {
      Client: process.env.REACT_APP_MEWS_API_CLIENT,
      HotelId: hotel.Id,
      StartUtc: booking.StartUtc,
      EndUtc: booking.EndUtc,
      VoucherCode: voucherCode,
      RoomCategoryId: reservation.RoomCategoryId,
      Occupancies: [
        {
          AdultCount: reservation.AdultCount,
          ChildCount: reservation.ChildCount,
        },
      ],
      ProductIds: reservation.ProductIds,
    },
  });
}

export function getReservationPrice(hotel: IHotelState, booking: IBookingState) {
  return axios({
    method: "post",
    url: process.env.REACT_APP_MEWS_API_URL + "reservations/price",
    data: {
      Client: process.env.REACT_APP_MEWS_API_CLIENT,
      ConfigurationId: hotel.ConfigurationId,
      CurrencyCode: hotel.DefaultCurrencyCode,
      Reservations: booking.Reservations,
    },
  });
}

export function getPaymentCardsAll(PaymentCardId: IbookingPayment["PaymentCardId"]) {
  return axios({
    method: "post",
    url: process.env.REACT_APP_MEWS_API_URL + "paymentCards/getAll",
    data: {
      Client: process.env.REACT_APP_MEWS_API_CLIENT,
      PaymentCardIds: [PaymentCardId],
    },
  });
}

export function getPaymentCardsAuthorize(
  hotel: IHotelState,
  PaymentCardId: IbookingPayment["PaymentCardId"]
) {
  const date = new Date();
  return axios({
    method: "post",
    url: process.env.REACT_APP_MEWS_API_URL + "paymentCards/authorize",
    data: {
      Client: process.env.REACT_APP_MEWS_API_CLIENT,
      EnterpriseId: hotel.EnterpriseId,
      PaymentCardId: PaymentCardId,
      BrowserInfo: {
        ScreenWidth: window.screen.width,
        ScreenHeight: window.screen.height,
        ColorDepth: window.screen.colorDepth,
        UserAgent: navigator.userAgent,
        Language: navigator.language,
        JavaEnabled: false,
        TimeZoneOffset: date.getTimezoneOffset(),
      },
    },
  });
}

export function validateVoucherCode(hotel: IHotelState, VoucherCode: IBookingState["VoucherCode"]) {
  return axios({
    method: "post",
    url: process.env.REACT_APP_MEWS_API_URL + "vouchers/validate",
    data: {
      Client: process.env.REACT_APP_MEWS_API_CLIENT,
      HotelId: hotel.Id,
      VoucherCode,
    },
  });
}
