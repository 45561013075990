import { store } from "../app/store";

export const defaultCurrency = () => {
  const { hotel } = store.getState();
  const currency = hotel.Currencies.find((c) => c.Code === hotel.DefaultCurrencyCode);
  if (currency) return currency;

  // Default
  return {
    Code: "EUR",
    Symbol: "€",
    ValueFormat: '"€"#,##0.00;−"€"#,##0.00',
    DecimalPlaces: 2,
    SymbolIsBehindValue: false,
  };
};

export const defaultLanguageCode = () => {
  const { hotel } = store.getState();
  return hotel.DefaultLanguageCode;
}

export const formatCurrency = (price: number) => {
  const { hotel } = store.getState();
  const formatter = new Intl.NumberFormat(hotel.DefaultLanguageCode, {
    style: "currency",
    currency: hotel.DefaultCurrencyCode,
    minimumFractionDigits: 2,
  });

  return formatter.format(price);
};
