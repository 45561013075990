function Loader(props: { classes?: string }) {
  const { classes = "" } = props;
  return (
    <div className="flex justify-end">
      <span
        className={`block mr-6 h-3 w-3 bg-gray-800 rounded-full animate-flash ${classes}`}
      ></span>
    </div>
  );
}

export default Loader;
