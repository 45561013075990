import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { IHotelState } from "./hotelInterface";
import {
  fetchConfiguration,
  fetchHotelConfiguration,
  fetchHotelPaymentConfiguration,
  fetchHotelRoomAvailability,
} from "./hotelApi";
import { IBookingState } from "../booking/bookingInterface";

const initialState: IHotelState = {
  Id: "",
  HotelCode: "",
  EnterpriseId: "",
  ConfigurationId: "",
  DirectPaymentId: "",
  Status: "idle",
  Currencies: [],
  DefaultCurrencyCode: "EUR",
  DefaultLanguageCode: "en-US",
  DefaultRateCurrencyCode: "EUR",
  Products: [],
  Rates: [],
  RoomCategories: [],
  RoomCategoryAvailabilities: [],
  CompetitorPriceRelativeAdjustment: 0,
  PaymentGateway: {
    PaymentGatewayType: "",
    IsMerchant: false,
    PublicKey: "",
    SupportedCreditCardTypes: []
  },
};

// Get hotel configuration
export const getHotelConfiguration = createAsyncThunk(
  "hotel/getHotelConfiguration",
  async (Id: IHotelState["Id"]) => {
    const response = await fetchHotelConfiguration(Id);
    return response.data;
  }
);

// Get hotel payment configuration
export const getHotelPaymentConfiguration = createAsyncThunk(
  "hotel/getHotelPaymentConfiguration",
  async (Id: IHotelState["Id"]) => {
    const response = await fetchHotelPaymentConfiguration(Id);
    return response.data;
  }
);

// Get configuration for services
export const getConfiguration = createAsyncThunk(
  "hotel/getConfiguration",
  async (ConfigurationId: IHotelState["ConfigurationId"]) => {
    const response = await fetchConfiguration(ConfigurationId);
    return response.data;
  }
);

// Get room availability
export const getRoomAvailability = createAsyncThunk(
  "hotel/getRoomAvailability",
  async (
    {
      StartUtc,
      EndUtc,
      VoucherCode,
    }: {
      StartUtc: IBookingState["StartUtc"];
      EndUtc: IBookingState["EndUtc"];
      VoucherCode: IBookingState["VoucherCode"];
    },
    { getState }
  ) => {
    const { hotel, booking }: { hotel: IHotelState; booking: IBookingState } = getState() as any;
    if (hotel.Id !== "") {
      const response = await fetchHotelRoomAvailability(
        hotel,
        booking,
        StartUtc,
        EndUtc,
        VoucherCode
      );
      return response.data;
    }
  }
);

export const hotelSlice = createSlice({
  name: "hotel",
  initialState,
  reducers: {
    setId: (
      state,
      action: PayloadAction<{
        Id: IHotelState["Id"];
        HotelCode: IHotelState["HotelCode"];
        ConfigurationId: IHotelState["ConfigurationId"];
        DirectPaymentId: IHotelState["DirectPaymentId"];
      }>
    ) => {
      state.Id = action.payload.Id;
      state.HotelCode = action.payload.HotelCode;
      state.ConfigurationId = action.payload.ConfigurationId;
      state.DirectPaymentId = action.payload.DirectPaymentId;
    },
    clearHotel: (state) => {
      state.Id = "";
      state.HotelCode = "";
      state.ConfigurationId = "";
      state.DirectPaymentId = "";
      state.EnterpriseId = "";
      state.Status = "idle";
      state.Currencies = [];
      state.DefaultCurrencyCode = "EUR";
      state.DefaultLanguageCode = "en-US";
      state.DefaultRateCurrencyCode = "EUR";
      state.Products = [];
      state.Rates = [];
      state.RoomCategories = [];
      state.RoomCategoryAvailabilities = [];
      state.CityId = undefined;
      state.ImageBaseUrl = undefined;
      state.ImageId = undefined;
      state.IntroImageId = undefined;
      state.Name = undefined;
      state.Pricing = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHotelConfiguration.pending, (state) => {
        state.Status = "loading";
      })
      .addCase(getConfiguration.fulfilled, (state, action) => {
        // console.log("<= Get config =>", action.payload);
        if (action.payload.Configurations && action.payload.Configurations[0]) {
          state.CompetitorPriceRelativeAdjustment =
            action.payload.Configurations[0].CompetitorPriceRelativeAdjustment;
          state.EnterpriseId = action.payload.Configurations[0].Enterprise.Id;
        } else {
          state.CompetitorPriceRelativeAdjustment = 1.2;
        }
      })
      .addCase(getHotelConfiguration.fulfilled, (state, action) => {
        // console.log("<= Get hotel config =>", action.payload);

        state.Status = "loaded";
        state.CityId = action.payload.CityId;
        state.Currencies = action.payload.Currencies;
        state.DefaultCurrencyCode = action.payload.DefaultCurrencyCode;
        state.DefaultLanguageCode = action.payload.DefaultLanguageCode;
        state.DefaultRateCurrencyCode = action.payload.DefaultRateCurrencyCode;
        state.ImageBaseUrl = action.payload.ImageBaseUrl;
        state.ImageId = action.payload.ImageId;
        state.IntroImageId = action.payload.IntroImageId;
        state.Name = action.payload.Name;
        state.Pricing = action.payload.Pricing;
        state.Products = action.payload.Products;
        state.RoomCategories = action.payload.RoomCategories;
      })
      .addCase(getHotelConfiguration.rejected, (state) => {
        state.Status = "loading";
      })
      .addCase(getHotelPaymentConfiguration.fulfilled, (state, action) => {
        // console.log("<= Get payment config =>", action.payload);
        if (
          action.payload.PaymentGateway !== null &&
          action.payload.PaymentGateway.PaymentGatewayType === "PciProxy"
        ) {
          state.PaymentGateway.PaymentGatewayType =
            action.payload.PaymentGateway.PaymentGatewayType;
          state.PaymentGateway.IsMerchant = action.payload.PaymentGateway.IsMerchant;
          state.PaymentGateway.PublicKey = action.payload.PaymentGateway.PublicKey;
          state.PaymentGateway.SupportedCreditCardTypes = action.payload.PaymentGateway.SupportedCreditCardTypes;
        }
      })
      .addCase(getRoomAvailability.pending, (state) => {})
      .addCase(getRoomAvailability.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.Rates = action.payload.Rates;
          state.RoomCategoryAvailabilities = action.payload.RoomCategoryAvailabilities;
        }
      })
      .addCase(getRoomAvailability.rejected, (state, action) => {
        console.log("getRoomAvailability.rejected", action.payload);
        // Reset hotel

        state.Id = "";
        state.HotelCode = "";
        state.ConfigurationId = "";
        state.DirectPaymentId = "";
        state.EnterpriseId = "";
        state.Status = "idle";
        state.Currencies = [];
        state.DefaultCurrencyCode = "EUR";
        state.DefaultLanguageCode = "en-US";
        state.DefaultRateCurrencyCode = "EUR";
        state.Products = [];
        state.Rates = [];
        state.RoomCategories = [];
        state.RoomCategoryAvailabilities = [];
        state.CityId = undefined;
        state.ImageBaseUrl = undefined;
        state.ImageId = undefined;
        state.IntroImageId = undefined;
        state.Name = undefined;
        state.Pricing = undefined;
      });
  },
});

export const { setId, clearHotel } = hotelSlice.actions;

// Selectors
export const selectId = (state: RootState) => state.hotel.Id;
export const selectHotel = (state: RootState) => state.hotel;

export default hotelSlice.reducer;
