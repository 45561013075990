import { NavLink } from "react-router-dom";

export default function PageNotFound() {
  return (
      <div className="flex flex-col items-center justify-center p-4 text-center">
        <div className="text-h3">
          Whoopsss,...<br />We can not find the page you're looking for.
        </div>
        <h4 className="mt-6"><NavLink className="color-black" to={`/`}>Click here to select a hotel.</NavLink></h4>
      </div>
  );
}
