import { store } from "../app/store";
import { IBookingReservation, IBookingProduct } from "../features/booking/bookingInterface";
import {
  IHotelProduct,
  IHotelRoomCategory,
  IHotelRoomOccupancyAvailabilityPricing,
} from "../features/hotel/hotelInterface";
import { findRoomCategory } from "./roomCategory";

export const getNormalBedCount = (categoryId: IHotelRoomCategory["Id"]) => {
  const category = findRoomCategory(categoryId);
  if (category) return category.NormalBedCount;
  return 0;
};

export const hasProduct = (id: IBookingProduct["Id"]) => {
  const { booking } = store.getState();
  return booking.Products.some((p) => p.Id === id);
};

export const findProduct = (id: IHotelProduct["Id"]) => {
  const { hotel } = store.getState();
  return hotel.Products.find((p) => p.Id === id);
};

export const findBookingProductDates = (id: IBookingProduct["Id"]) => {
  const { booking } = store.getState();
  const product = booking.Products.find((p) => p.Id === id);
  if (product) {
    return product.Dates;
  }
  return null;
};

export const getPriceByRate = (
  Reservation: IBookingReservation
): IHotelRoomOccupancyAvailabilityPricing | null => {
  const { hotel } = store.getState();
  const rca = hotel.RoomCategoryAvailabilities.find(
    (rca) => rca.RoomCategoryId === Reservation.RoomCategoryId
  );
  if (rca) {
    const roa = rca.RoomOccupancyAvailabilities.find(
      (roa) =>
        roa.AdultCount === Reservation.AdultCount && roa.ChildCount === Reservation.ChildCount
    );
    if (roa) {
      return roa.Pricing.find((p) => p.RateId === Reservation.RateId) ?? null;
    }
  }
  return null;
};
