import Reservations from "../components/Accommodation/Reservations";
import { motion } from "framer-motion";

function Accommodation() {
  return (
    <motion.div
    initial={{opacity: 0, y: "-12px"}}
    animate={{opacity:1, y: "0px"}}
    exit={{opacity: 0, y: "-12px"}}
    className="absolute inset-0 overflow-y-auto w-full px-4 pb-2 pb-4"
    >
      <Reservations />
    </motion.div>
  );
}

export default Accommodation;
