import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { validateVoucherCode } from "../../features/booking/bookingApi";
import { setBookingVoucherCode } from "../../features/booking/bookingSlice";
import { selectHotel } from "../../features/hotel/hotelSlice";
import { createRef, useEffect, useRef, useState } from "react";
import Refresh from "../Svg/Refresh";
import Check from "../Svg/Check";
import ArrowSmall from "../Svg/ArrowSmall";

interface IInputField {
  type: string;
  label: string;
  name: string;
  value: string | null;
  error: boolean | null;
  validate: { [key: string]: string | number | boolean };
  errorMessages: { [key: string]: string };
  valueCallback: (obj: { [key: string]: string }) => void;
  validationCallBack: (obj: { [key: string]: boolean | null }) => void;
}

function VoucherField(props: IInputField) {
  const { type, label, name, value, error, valueCallback } = props;

  const dispatch = useAppDispatch();
  const hotel = useAppSelector(selectHotel);
  const [active, setActive] = useState<boolean>(false);
  const inputRef = createRef<HTMLInputElement>();
  const isInitialMount = useRef(true);
  const [voucherState, setVoucherState] = useState<string>("");

  useEffect(() => {
    if (value !== "") {
      setActive(true);
    }

    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (value !== "") {
        handleBlur();
      }
    } else {
      handleBlur();

      const delayDebounceFn = setTimeout(() => {
        if (value !== "" && value !== null) {
          validateVoucher();
        }
      }, 1500);

      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, error]);

  const handleFocus = () => {
    setActive(true);
    inputRef.current?.focus();
  };

  const handleBlur = () => {
    setVoucherState("");
  };

  const handleInput = (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const value: string = e.currentTarget.value.trim();
    let obj: { [key: string]: string } = {};
    obj[e.currentTarget.name as keyof typeof obj] = value;
    valueCallback(obj);
  };

  const validateVoucher = () => {
    setVoucherState("loading");
    validateVoucherCode(hotel, value).then((response) => {
      if (response.data.IsValid) {
        setVoucherState("valid");
        dispatch(setBookingVoucherCode(value));
      } else {
        setVoucherState("inValid");
      }
    });
  };

  return (
    <div className={`mt-4`} onClick={() => handleFocus()}>
      <label htmlFor="">{label}</label>
      <div
        className={`flex items-center gap-2 bg-white rounded-md p-2 border mt-1 ${
          active ? "c-form__element--active" : ""
        } ${voucherState === "inValid" ? "border-red-600" : (
          voucherState === "valid" ? "border-green-600" : "border-white"
        )}`}
      >
        <div className="flex-grow w-full">
          <input
            ref={inputRef}
            name={name}
            type={type}
            value={value ?? ""}
            onChange={handleInput}
            onFocus={() => handleFocus()}
            onBlur={() => handleBlur()}
            className="w-full border-transparent focus:border-transparent focus:ring-0 p-0"
          />
        </div>

        <div
          className={` cursor-pointer ${active && voucherState !== "valid" ? "" : "hidden"}`}
          onClick={() => validateVoucher()}
        >
          <Refresh className={`w-6 h-6 ${voucherState === "loading" ? "animate-spin" : "hidden"}`} />
          <ArrowSmall className={`w-6 h-6 ${voucherState === "loading" ? "hidden" : ""}`} />
        </div>

        <div className={`text-green-600 ${active && voucherState === "valid" ? "" : "hidden"}`}>
          <Check />
        </div>
      </div>

      {voucherState === "inValid" && (
        <div className="text-small text-red-600 text-right mt-1">This voucher code is not correct</div>
      )}
    </div>
  );
}

export default VoucherField;
