import { store } from "../app/store";
import { findRoomCategory } from "./roomCategory";
import { findRate } from "./rate";
import { findProduct, getPriceByRate } from "./reservation";
import { IHotelProduct } from "../features/hotel/hotelInterface";
import { IBookingProduct, IBookingReservation } from "../features/booking/bookingInterface";

const reservationDetails = (reservation: IBookingReservation) => {
  const { hotel } = store.getState();
  const roomCategory = findRoomCategory(reservation.RoomCategoryId);
  const rate = findRate(reservation.RateId);
  const pricing = getPriceByRate(reservation);
  const price = pricing?.Price.Total[hotel.DefaultCurrencyCode] ?? 0;

  let discountPrice = 0;
  if(pricing){
    discountPrice = (pricing?.MaxPrice.Total[hotel.DefaultCurrencyCode] - pricing?.Price.Total[hotel.DefaultCurrencyCode]);
  }

  return { roomCategory, rate, price, discountPrice };
};

const getReservationItem = (reservation: IBookingReservation) => {
  const { hotel } = store.getState();
  const { roomCategory, rate, price, discountPrice } = reservationDetails(reservation);
  const item = {
    item_id: reservation.RoomCategoryId,
    item_name: roomCategory?.Name[hotel.DefaultLanguageCode],
    item_variant: rate?.Name[hotel.DefaultLanguageCode],
    item_category: "reservation",
    item_category2: `Cityhub ${hotel.HotelCode}`,
    item_category3: hotel.HotelCode,
    price,
    currency: hotel.DefaultCurrencyCode,
    quantity: "1",
    coupon: reservation.VoucherCode,
    discount: discountPrice,
    // index,
    // affiliation: 'Cityhub CPH',
  };

  return item;
}

const getProductItem = (product: IBookingProduct) => {
  const { hotel, booking } = store.getState();
  const hotelProduct = findProduct(product.Id);
  const item = {
    item_id: product.Id,
    item_name: hotelProduct?.Name[hotel.DefaultLanguageCode],
    item_category: "product",
    item_category2: `Cityhub ${hotel.HotelCode}`,
    item_category3: hotel.HotelCode,
    price: product.Price,
    currency: hotel.DefaultCurrencyCode,
    quantity: product.Count,
    coupon: booking.VoucherCode,
    discount: "0",
    // index: "1",
    // affiliation: 'Cityhub CPH',
  };

  return item;
}

const getItems = () => {
  const { booking } = store.getState();
  const items: any[] = [];

  // Reservation
  booking.Reservations.forEach((reservation) => {
    items.push(getReservationItem(reservation));
  });

  // Products
  booking.Products.forEach((product) => {
    items.push(getProductItem(product));
  });

  return items;
};

export const GTMTrackingSelectCity = () => {
  const { hotel } = store.getState();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "select_city",
    location: hotel.HotelCode,
    url: window.location.href,
  });
};

export const GTMTrackingSelectPage = (event: string) => {
  const { hotel } = store.getState();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event.replace("/", ""),
    location: hotel.HotelCode,
    url: window.location.href,
  });
};

/**
 * View all items in the list
 */
export const GTMTrackingViewItemList = () => {
  const { hotel } = store.getState();
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "select_item",
    location: hotel.HotelCode,
    ecommerce: {
      items: getItems(),
    },
  });

  // Reset commerce
  window.dataLayer.push({ ecommerce: null });
};

/**
 * When an accommodation is added to the reservations
 * Get last reservation
 */
export const GTMTrackingSelectAccommodation = () => {
  const { hotel, booking } = store.getState();
  window.dataLayer = window.dataLayer || [];
  const reservation = [...booking.Reservations].pop();

  if (reservation !== undefined) {
    window.dataLayer.push({
      event: "select_item",
      location: hotel.HotelCode,
      ecommerce: {
        items: [getReservationItem(reservation)],
      },
    });

    // Reset commerce
    window.dataLayer.push({ ecommerce: null });
  }
};


/**
 * When a product is added to the booking products
 * Get last product
 */
export const GTMTrackingSelectProduct = () => {
  const { hotel, booking } = store.getState();
  window.dataLayer = window.dataLayer || [];
  const product = [...booking.Products].pop();

  if (product !== undefined) {
    window.dataLayer.push({
      event: "select_item",
      location: hotel.HotelCode,
      ecommerce: {
        items: [getProductItem(product)],
      },
    });

    // Reset commerce
    window.dataLayer.push({ ecommerce: null });
  }
};

/**
 * When an accommodation is removed
 */
export const GTMTrackingRemoveAccommodation = () => {
  const { hotel } = store.getState();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "remove_accommodation",
    location: hotel.HotelCode,
    url: window.location.href,
  });
};

/**
 * When a product is removed
 */
export const GTMTrackingRemoveProduct = (id: IHotelProduct["Id"]) => {
  const { hotel } = store.getState();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "remove_product",
    location: hotel.HotelCode,
    url: window.location.href,
  });
};

/**
 * Add to cart
 */
export const GTMTrackingAddToCart = () => {
  const { hotel, booking } = store.getState();
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "add_to_cart",
    location: hotel.HotelCode,
    ecommerce: {
      currency: hotel.DefaultCurrencyCode,
      value: booking.ReservationPrice.GrossValue,
      items: getItems(),
    },
  });

  // Reset commerce
  window.dataLayer.push({ ecommerce: null });
};

/**
 * Begin checkout
 */
export const GTMTrackingBeginCheckout = () => {
  const { hotel, booking } = store.getState();
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "begin_checkout",
    location: hotel.HotelCode,
    ecommerce: {
      currency: hotel.DefaultCurrencyCode,
      value: booking.ReservationPrice.GrossValue,
      coupon: booking.VoucherCode,
      items: getItems(),
    },
  });

  // Reset commerce
  window.dataLayer.push({ ecommerce: null });
};

/**
 * Add payment info
 */
export const GTMTrackingAddPaymentInfo = (paymentMethod: string) => {
  const { hotel, booking } = store.getState();
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "add_payment_info",
    location: hotel.HotelCode,
    ecommerce: {
      currency: hotel.DefaultCurrencyCode,
      value: booking.ReservationPrice.GrossValue,
      coupon: booking.VoucherCode,
      payment_type: paymentMethod,
      items: getItems(),
    },
  });

  // Reset commerce
  window.dataLayer.push({ ecommerce: null });
};

/**
 * Purchase
 */
export const GTMTrackingPurchase = () => {
  const { hotel, booking } = store.getState();
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: "purchase",
    location: hotel.HotelCode,
    ecommerce: {
      transaction_id: booking.Payment.ReservationGroupId,
      currency: hotel.DefaultCurrencyCode,
      tax: booking.ReservationPrice.TaxValue.toFixed(2),
      value: booking.ReservationPrice.GrossValue,
      coupon: booking.VoucherCode,
      items: getItems(),
    },
  });

  // Reset commerce
  window.dataLayer.push({ ecommerce: null });
};

/**
 * Remove a product from cart
 */
export const GTMTrackingRemoveProductFromCart = (id: IHotelProduct["Id"]) => {
  const { hotel, booking } = store.getState();
  const product = booking.Products.find((p) => p.Id === id);
  if(product){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "remove_product_from_cart",
      location: hotel.HotelCode,
      url: window.location.href,
      ecommerce: {
        currency: hotel.DefaultCurrencyCode,
        value: booking.ReservationPrice.GrossValue,   
        items: [getProductItem(product)]
      }
    });

    // Reset commerce
    window.dataLayer.push({ ecommerce: null });
  }
};

/**
 * Remove a accommodation from cart
 */
export const GTMTrackingRemoveAccommodationFromCart = (number: number) => {
  const { hotel, booking } = store.getState();
  const reservation = booking.Reservations[number];
  if(reservation){
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "remove_accommodation_from_cart",
      location: hotel.HotelCode,
      url: window.location.href,
      ecommerce: {
        currency: hotel.DefaultCurrencyCode,
        value: booking.ReservationPrice.GrossValue,   
        items: [getReservationItem(reservation)]
      }
    });

    // Reset commerce
    window.dataLayer.push({ ecommerce: null });
  }
};

/**
 * No date available
 */

export const GTMTrackingNoDatesAvailable = () => {
  const { hotel } = store.getState();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "date_not_available",
    location: hotel.HotelCode,
    url: window.location.href,
  });
};
