import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setId, getHotelConfiguration, getConfiguration, selectHotel, getHotelPaymentConfiguration } from "../../features/hotel/hotelSlice";
import { IHotelState } from "../../features/hotel/hotelInterface";
import { clearBooking, clearBookingPayment } from "../../features/booking/bookingSlice";
import { useState } from "react";
import { motion } from "framer-motion";
import { GTMTrackingSelectCity } from "../../helpers/GTMTracking";

export default function Select() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hotel = useAppSelector(selectHotel);
  const [selectedHotel, setSelectedHotel] = useState<IHotelState["HotelCode"]>(hotel.HotelCode);

  const getHotel = (
    HotelCode: IHotelState["HotelCode"],
    Id: IHotelState["Id"],
    ConfigurationId: IHotelState["ConfigurationId"],
    DirectPaymentId: IHotelState["DirectPaymentId"]
  ) => {

    // Do nothing if the hotel is the same
    if(hotel.HotelCode === HotelCode){
      navigate("/calendar");
      return ;
    }

    let DPRateGroupID:string = "";
    if(DirectPaymentId.length > 15){
      DPRateGroupID = DirectPaymentId;
    }
    
    // Add new hotel
    setSelectedHotel(HotelCode);
    dispatch(setId({ Id, HotelCode, ConfigurationId, DirectPaymentId: DPRateGroupID }));
    dispatch(getConfiguration(ConfigurationId));
    dispatch(getHotelPaymentConfiguration(Id));
    // Get hotel config and set scene to calendar
    const fetchConfig = async () => {
      await dispatch(getHotelConfiguration(Id));
    };
    
    fetchConfig().then(() => {
      
      // GTM DATALAYER
      GTMTrackingSelectCity();
      // END GTM DATALAYER

      dispatch(clearBooking());
      dispatch(clearBookingPayment());
      setTimeout(() => {
        navigate("/calendar");
      }, 500);
    });
  };

  const motionTransition = {type: "spring", stiffness: 100, duration: 0.5};
  const motionVariants = {
    closed: { opacity: 1 },
    open: { opacity: 0.25 },
  };

  return (

    <ul>
    <motion.li
      transition={motionTransition}
      variants={motionVariants}
      animate={selectedHotel === "AMS" ? "open" : "closed"}
      className="h1 cursor-pointer"
      onClick={() =>
        getHotel(
          "AMS",
          process.env.REACT_APP_MEWS_AMS_ID as string,
          process.env.REACT_APP_MEWS_AMS_CONFIGURATION_ID as string,
          process.env.REACT_APP_MEWS_AMS_DP_RATE_ID as string
        )
      }
    >
      Amsterdam
    </motion.li>
    <motion.li
      transition={motionTransition}
      variants={motionVariants}
      animate={selectedHotel === "RTM" ? "open" : "closed"}
      className="h1 cursor-pointer"
      onClick={() =>
        getHotel(
          "RTM",
          process.env.REACT_APP_MEWS_RTM_ID as string,
          process.env.REACT_APP_MEWS_RTM_CONFIGURATION_ID as string,
          process.env.REACT_APP_MEWS_RTM_DP_RATE_ID as string
        )
      }
    >
      Rotterdam
    </motion.li>
    <motion.li
      transition={motionTransition}
      variants={motionVariants}
      animate={selectedHotel === "CPH" ? "open" : "closed"}
      className="h1 cursor-pointer"
      onClick={() =>
        getHotel(
          "CPH",
          process.env.REACT_APP_MEWS_CPH_ID as string,
          process.env.REACT_APP_MEWS_CPH_CONFIGURATION_ID as string,
          process.env.REACT_APP_MEWS_CPH_DP_RATE_ID as string
        )
      }
    >
      Copenhagen
    </motion.li>
    <motion.li
      transition={motionTransition}
      variants={motionVariants}
      animate={selectedHotel === "RKV" ? "open" : "closed"}
      className="h1 cursor-pointer"
      onClick={() =>
        getHotel(
          "RKV",
          process.env.REACT_APP_MEWS_RKV_ID as string,
          process.env.REACT_APP_MEWS_RKV_CONFIGURATION_ID as string,
          process.env.REACT_APP_MEWS_RKV_DP_RATE_ID as string
        )
      }
    >
      Reykjavík
    </motion.li>
  </ul>
  );
}
