import { SVGProps } from "react";

export default function Room(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 128 128"
      width="1em"
      height="1em"
      fill="currentColor"
      className="w-8 h-8"
      {...props}
    >
      <path d="M104 87v5H24v-5a8 8 0 0 1 8-8h64a8 8 0 0 1 8 8zm0-40a8 8 0 0 0-8-8H32a8 8 0 0 0-8 8v5h80zm16-19v88a4 4 0 0 1-8 0v-12H16v12a4 4 0 0 1-8 0V12a4 4 0 0 1 8 0v44h96V28a4 4 0 0 1 8 0zM16 96h96V64H16z"></path>
    </svg>
  );
}
