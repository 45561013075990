import React from 'react'

import AMX from '../../assets/images/brands/AMX.svg'
import CUP from '../../assets/images/brands/CUP.svg'
import DIN from '../../assets/images/brands/DIN.svg'
import DIS from '../../assets/images/brands/DIS.svg'
import DNK from '../../assets/images/brands/DNK.svg'
import ECA from '../../assets/images/brands/ECA.svg'
import ELO from '../../assets/images/brands/ELO.svg'
import JCB from '../../assets/images/brands/JCB.svg'
import MAU from '../../assets/images/brands/MAU.svg'
import UAP from '../../assets/images/brands/UAP.svg'
import VIS from '../../assets/images/brands/VIS.svg'
import VPY from '../../assets/images/brands/VPY.svg'
import cardEmpty from '../../assets/images/card-empty.svg'
import cardError from '../../assets/images/card-error.svg'
import cardRecognized from '../../assets/images/card-recognized.svg'
import cvcEmpty from '../../assets/images/cvc-empty.svg'
import cvcError from '../../assets/images/cvc-error.svg'

const ICON_MAP: {[key: string]: any} = {
  'card-empty': cardEmpty,
  'card-error': cardError,
  'card-recognized': cardRecognized,
  'cvv-empty': cvcEmpty,
  'cvv-error': cvcError,
  'cvc-empty': cvcEmpty,
  'cvc-error': cvcError,
  'brands/AMX': AMX,
  'brands/CUP': CUP,
  'brands/DIN': DIN,
  'brands/DIS': DIS,
  'brands/DNK': DNK,
  'brands/ECA': ECA,
  'brands/ELO': ELO,
  'brands/JCB': JCB,
  'brands/MAU': MAU,
  'brands/UAP': UAP,
  'brands/VIS': VIS,
  'brands/VPY': VPY
}

interface ISecureFieldIcon {
  fieldType: string;
  iconType: string
}

export default function SecureFieldIcon(props: ISecureFieldIcon) {
  const { fieldType, iconType } = props;
  return (
    <img
      alt={fieldType + '-' + iconType}
      className={'h-6 w-6 secure-field--card-icon-' + iconType}
      src={ICON_MAP[iconType]}
    />
  )
}
