import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import { setId, getHotelConfiguration, getConfiguration, getHotelPaymentConfiguration } from "../features/hotel/hotelSlice";
import { IHotelState } from "../features/hotel/hotelInterface";
import { clearBooking } from "../features/booking/bookingSlice";
import { useEffect, useState } from "react";
import ContentOverlay from "../components/ContentOverlay";
import { setReturnUrl } from "../features/config/configSlice";

export default function RedirectHotel({hotelCode}: {hotelCode: string}) {
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [overlayContent, setOverlayContent] = useState<string>("");

  const getHotel = (
    HotelCode: IHotelState["HotelCode"],
    Id: IHotelState["Id"],
    ConfigurationId: IHotelState["ConfigurationId"],
    DirectPaymentId: IHotelState["DirectPaymentId"]
  ) => {

    // Get and set return URL
    const urlParams = new URLSearchParams(window.location.search);
    const returnUrl = urlParams.get('returnUrl')
    dispatch(setReturnUrl(returnUrl));

    let DPRateGroupID:string = "";
    if(DirectPaymentId.length > 15){
      DPRateGroupID = DirectPaymentId;
    }
    dispatch(setId({ Id, HotelCode, ConfigurationId, DirectPaymentId: DPRateGroupID }));
    dispatch(getConfiguration(ConfigurationId));
    dispatch(getHotelPaymentConfiguration(Id));
    
    // Get hotel config and set scene to calendar
    const fetchConfig = async () => {
      await dispatch(getHotelConfiguration(Id));
    };
    fetchConfig().then(() => {
      dispatch(clearBooking());
      setTimeout(() => {
        navigate("/calendar");
      }, 500);
    });
  };

  useEffect(() => {

    if (hotelCode === "AMS") {
      setOverlayContent("Loading Amsterdam");
      getHotel(
        "AMS",
        process.env.REACT_APP_MEWS_AMS_ID as string,
        process.env.REACT_APP_MEWS_AMS_CONFIGURATION_ID as string,
        process.env.REACT_APP_MEWS_AMS_DP_RATE_ID as string
      );
    } else if (hotelCode === "RTM") {
      setOverlayContent("Loading Rotterdam");
      getHotel(
        "RTM",
        process.env.REACT_APP_MEWS_RTM_ID as string,
        process.env.REACT_APP_MEWS_RTM_CONFIGURATION_ID as string,
        process.env.REACT_APP_MEWS_RTM_DP_RATE_ID as string
      );
    } else if (hotelCode === "CPH") {
      setOverlayContent("Loading Copenhagen");
      getHotel(
        "CPH",
        process.env.REACT_APP_MEWS_CPH_ID as string,
        process.env.REACT_APP_MEWS_CPH_CONFIGURATION_ID as string,
        process.env.REACT_APP_MEWS_CPH_DP_RATE_ID as string
      );
    }  else if (hotelCode === "RKV") {
      setOverlayContent("Loading Reykjavik");
      getHotel(
        "RKV",
        process.env.REACT_APP_MEWS_RKV_ID as string,
        process.env.REACT_APP_MEWS_RKV_CONFIGURATION_ID as string,
        process.env.REACT_APP_MEWS_RKV_DP_RATE_ID as string
      );
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (<ContentOverlay show={true} content={overlayContent} />);
}
