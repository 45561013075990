import Button from "./Button";
import ArrowSmall from "./Svg/ArrowSmall";
import UspBar from "./UspBar";

interface INextProps {
  next: () => void;
}

export default function Next({ next }: INextProps) {
  return (
    <div className={`w-full bg-white rounded-t-lg p-2 relative`}>
      <UspBar />
      <div className="mt-2">
        <Button text="Next" onClick={() => next()} suffix={<ArrowSmall className="h-4 w-4" />} />
      </div>
    </div>
  );
}
