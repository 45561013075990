import { useAppSelector } from "../app/hooks";
import { selectBooking } from "../features/booking/bookingSlice";
import { selectHotel } from "../features/hotel/hotelSlice";
import { useEffect, useState } from "react";
import Loader from "./Loader";

export const LoaderOverlay = () => {

  const hotel = useAppSelector(selectHotel);
  const booking = useAppSelector(selectBooking);

  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    setShow(false);
    
    if(hotel.Status === 'loading'){
      // setShow(true);
    }

  }, [hotel.Status, booking.ReservationPrice, booking.Payment]);

  return (
    <div className={`fixed inset-0 ${show ? '' : 'hidden'}`}>
      <Loader />
    </div>
  );
}
