import { createRef, useEffect, useRef, ReactElement } from "react";
import Check from "../Svg/Check";

interface ICheckBoxField {
  label: string | ReactElement;
  name: string;
  value: boolean;
  error: boolean | null;
  validate: { [key: string]: string | number | boolean };
  valueCallback: (obj: { [key: string]: string | boolean }) => void;
  validationCallBack: (obj: { [key: string]: boolean | null }) => void;
}

function CheckBoxField(props: ICheckBoxField) {
  const { label, name, value, error, validate, valueCallback, validationCallBack } = props;
  const inputRef = createRef<HTMLInputElement>();
  const isInitialMount = useRef(true);

  const handleBlur = () => {
    let valid = null;
    Object.keys(validate).forEach((err) => {
      // Validate required
      if (err === "required" && !value) {
        valid = "required";
      }
    });

    let obj: { [key: string]: boolean | null } = {};
    if (valid === null) {
      obj[name as keyof typeof obj] = true;
      validationCallBack(obj);
    } else {
      obj[name as keyof typeof obj] = false;
      validationCallBack(obj);
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      if (value) {
        handleBlur();
      }
    } else {
      handleBlur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, error]);

  const handleInputCheckbox = (e: React.FormEvent<HTMLInputElement>) => {
    const value: boolean = e.currentTarget.checked;
    let obj: { [key: string]: boolean } = {};
    obj[e.currentTarget.name as keyof typeof obj] = value;
    valueCallback(obj);
  };

  return (
    <div className="flex">
      <div>
        <input
          type="checkbox"
          ref={inputRef}
          id={name}
          name={name}
          onChange={handleInputCheckbox}
          defaultChecked={value}
          className="hidden peer"
        />
        <label htmlFor={name} className={`flex items-center gap-2 w-full`}>
          <div
            className={`bg-white rounded-md border grow min-w-[2rem] max-w-[2rem] h-8 p-1 flex items-center justify-center ${
              error === false ? "border-red-600" : "border-black"
            }`}
          >
            {value && <Check className="w-full h-full" />}
          </div>
        </label>
      </div>
      <div className="ml-3 shrink">{label}</div>
    </div>
  );
}

export default CheckBoxField;
