import { useEffect, useState } from "react"

type IButtonProps = {
  id?: any
  prefix?: JSX.Element
  suffix?: JSX.Element
  text: string
  target?: "_blank" | "_self"
  type?: "submit" | "button"
  classes?: "primary" | "secondary" | "secondaryAlt" | "error" | "contrast" | "info"
  size?: "small" | "medium" | "large"
  disabled?: boolean
  onClick?: () => void
}

export default function Button(props: IButtonProps) {
  const [disabledClass, setDisabledClass] = useState<string>("")
  const {
    id = null,
    prefix = null,
    suffix = null,
    text,
    type = "button",
    classes = "primary",
    size = "large",
    disabled = false,
    onClick = undefined,
  } = props

  const styles: { [key: string]: string } = {
    primary: "bg-black text-white",
    secondary: "border border-black text-black bg-white bg-opacity-50",
    secondaryAlt: "text-black bg-white/25",
    error: "bg-red-600 text-white",
    contrast: "bg-white text-black",
    info: "bg-gray-600 text-white",
  }
  const sizes: { [key: string]: string } = {
    small: " p-2 w-full justify-between text-medium",
    medium: " p-2 w-full justify-between text-medium",
    large: " p-3 w-full justify-between",
  }

  useEffect(() => {
    if (disabled) {
      setDisabledClass(" opacity-25 pointer-events-none")
    } else {
      setDisabledClass("")
    }
  }, [disabled])

  return (
        <button
          id={id}
          className={
            `rounded-md flex items-center whitespace-nowrap ` +
            styles[classes] +
            sizes[size] +
            disabledClass
          }
          onClick={onClick}
          type={type}
        >
          {prefix && <div className="mr-2">{prefix}</div>}
          <span className={`leading-none flex-grow text-center ${suffix ? "ml-3" : ""}`}>{text}</span>
          {suffix && <div className="ml-2">{suffix}</div>}
        </button>
  )
}
