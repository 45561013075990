import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ISceneState } from "./sceneInterface";

const initialState: ISceneState = {
  Scenes: {}
};

export const sceneSlice = createSlice({
  name: "scene",
  initialState,
  reducers: {
    hasHotelConfiguration: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.Scenes = {Calendar: "unlocked"};
      } else {
        // state.Calendar = "locked";
      }
    },
    hasReservation: (state, action: PayloadAction<boolean>) => {
      // if (action.payload) {
      //   state.Accommodation = "unlocked";
      // } else {
      //   state.Accommodation = "locked";
      // }
    },
  },
});

export const { hasHotelConfiguration, hasReservation } = sceneSlice.actions;

// Selectors
export const selectScenes = (state: RootState) => state.scene;


export default sceneSlice.reducer;
