import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { IConfigState } from "./configInterface";

const initialState: IConfigState = {
  ReturnUrl: null,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setReturnUrl: (
      state,
      action: PayloadAction<IConfigState["ReturnUrl"]>
    ) => {
      state.ReturnUrl = action.payload;
    },
    removeReturnUrl: (state, action: PayloadAction) => {
      state.ReturnUrl = null;
    },
  },
});

export const { setReturnUrl, removeReturnUrl } =
configSlice.actions;

export const selectConfig = (state: RootState) => state.config;

export default configSlice.reducer;
