import { SVGProps } from "react";

export default function TwinHub(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 128 128"
      width="1em"
      height="1em"
      fill="currentColor"
      className="w-8 h-8"
      {...props}
    >
      <path d="M20 64h-8v-8a12 12 0 0 1 12-12h24a12 12 0 0 1 12 12v8h-8v-8a4 4 0 0 0-4-4H24a4 4 0 0 0-4 4zm92 20H72a8 8 0 0 0-8 8 8 8 0 0 0-8-8H16a8 8 0 0 0-8 8v8a4 4 0 0 0 8 0v-8h40v8a4 4 0 0 0 8 0 4 4 0 0 0 8 0v-8h40v8a4 4 0 0 0 8 0v-8a8 8 0 0 0-8-8zM76 56a4 4 0 0 1 4-4h24a4 4 0 0 1 4 4v8h8v-8a12 12 0 0 0-12-12H80a12 12 0 0 0-12 12v8h8zM60 76a8 8 0 0 0-8-8H20a8 8 0 0 0-8 8v4h48zm56 0a8 8 0 0 0-8-8H76a8 8 0 0 0-8 8v4h48z"></path>
    </svg>
  );
}
