import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  removeBookingRoom,
  selectBooking,
  setOpenReservation,
  updateBookingRoomCategory,
  updateBookingRoomOccupancy,
} from "../../features/booking/bookingSlice";
import { formatCurrency } from "../../helpers/currency";
import { IBookingReservation } from "../../features/booking/bookingInterface";
import { selectHotel } from "../../features/hotel/hotelSlice";
import { getNormalBedCount } from "../../helpers/reservation";
import { findRoomCategory } from "../../helpers/roomCategory";
import { getPriceByRate } from "../../helpers/reservation";
import CardSlider from "../CardSlider";
import "tippy.js/dist/tippy.css";
import { useEffect, useState } from "react";
import {
  GTMTrackingRemoveAccommodation,
  GTMTrackingSelectAccommodation,
} from "../../helpers/GTMTracking";
import { IHotelRoomCategory } from "../../features/hotel/hotelInterface";
import { getRoomAvailability } from "../../helpers/availability";
import Tippy from "@tippyjs/react";
import ReservationCardCategoryName from "./ReservationCardCategoryName";
import XMark from "../Svg/XMark";

export default function ReservationCard(props: {
  number: number;
  reservation: IBookingReservation;
}) {
  const { number, reservation } = props;
  const dispatch = useAppDispatch();
  const hotel = useAppSelector(selectHotel);
  const booking = useAppSelector(selectBooking);
  const roomCategory = findRoomCategory(reservation.RoomCategoryId);
  const normalBedCount = getNormalBedCount(reservation.RoomCategoryId);
  const [trackSelectItem, setTrackSelectItem] = useState<boolean>(false);
  const [rateDiscount, setRateDiscount] = useState<string>("");

  const updateAdultCount = (adultCount: number) => {
    dispatch(setOpenReservation(number));
    if (reservation.AdultCount !== adultCount) {
      dispatch(updateBookingRoomOccupancy({ roomKey: number, adultCount, childCount: 0 }));
    }
  };

  useEffect(() => {
    if (trackSelectItem) {
      // Only track last item
      GTMTrackingSelectAccommodation();
      setTrackSelectItem(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.Reservations]);

  useEffect(() => {
    const pricing = getPriceByRate(reservation);
    const rate = hotel.Rates.find((r) => r.Id === reservation.RateId);
    if (
      rate &&
      pricing !== null &&
      pricing.MaxPrice.Total[hotel.DefaultCurrencyCode] >
        pricing.Price.Total[hotel.DefaultCurrencyCode]
    ) {
      setRateDiscount(`${rate.Name[hotel.DefaultLanguageCode]}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pricing = getPriceByRate(reservation);
  const averagePerNight = pricing?.Price.AveragePerNight[hotel.DefaultCurrencyCode] ?? 0;
  const competitorPercent = hotel.CompetitorPriceRelativeAdjustment * 100 - 100;

  const removeReservation = () => {
    dispatch(removeBookingRoom(number));
    GTMTrackingRemoveAccommodation();
  };

  const updateBookingCategory = (roomCategoryId: IHotelRoomCategory["Id"] | undefined) => {
    if (roomCategoryId !== undefined) {
      dispatch(setOpenReservation(number));
      let adultCount: number = reservation.AdultCount;
      if (adultCount > 2) adultCount = 2;
      setTrackSelectItem(true);
      dispatch(
        updateBookingRoomCategory({ roomKey: number, roomCategoryId, adultCount, childCount: 0 })
      );
    }
  };

  return (
    <div>
      <div className="flex justify-between divide-x divide-white">
        {hotel.RoomCategories.map((category, i) => {
          if (category.Id === roomCategory?.Id) {
            return (
              <div
                key={i}
                className={`bg-white flex items-center gap-2 px-2 py-2 w-full cursor-pointer ${
                  i === 0 ? "rounded-tl-md" : ""
                } ${
                  booking.Reservations.length === 1 && i === hotel.RoomCategories.length - 1
                    ? "rounded-tr-md"
                    : ""
                }`}
              >
                <ReservationCardCategoryName name={category.Name[hotel.DefaultLanguageCode]} />
              </div>
            );
          } else if (getRoomAvailability(category.Id)) {
            return (
              <div
                key={i}
                className={`bg-white/50 flex items-center gap-2 px-2 py-3 w-full cursor-pointer ${
                  i === 0 ? "rounded-tl-md" : ""
                } ${
                  booking.Reservations.length === 1 && i === hotel.RoomCategories.length - 1
                    ? "rounded-tr-md"
                    : ""
                }`}
                onClick={() => {
                  updateBookingCategory(category.Id);
                }}
              >
                <ReservationCardCategoryName name={category.Name[hotel.DefaultLanguageCode]} />
              </div>
            );
          } else {
            return (
              <div
                key={i}
                className={`bg-white opacity-25 flex items-center gap-2 px-2 py-3 w-full cursor-pointer ${
                  i === 0 ? "rounded-tl-md" : ""
                } ${
                  booking.Reservations.length === 1 && i === hotel.RoomCategories.length - 1
                    ? "rounded-tr-md"
                    : ""
                }`}
              >
                <Tippy
                  content={
                    <span>No more {category.Name[hotel.DefaultLanguageCode]}s available.</span>
                  }
                >
                  <div className="text-sm">
                    <ReservationCardCategoryName name={category.Name[hotel.DefaultLanguageCode]} />
                  </div>
                </Tippy>
              </div>
            );
          }
        })}
        {booking.Reservations.length > 1 && (
          <div className="rounded-tr-md bg-white/50 flex items-center gap-2 px-2 py-2">
            <XMark
              className="w-5 h-5 cursor-pointer"
              onClick={() => {
                removeReservation();
              }}
            />
          </div>
        )}
      </div>
      <div className={`bg-white bg-opacity-75 relative rounded-b-md`}>
        {hotel.ImageBaseUrl && roomCategory && <CardSlider images={roomCategory?.ImageIds} />}

        <div className="p-4 space-y-2">
          <div className="text-h2 leading-none w-full flex justify-between">
            {roomCategory !== undefined && (
              <ReservationCardCategoryName
                name={roomCategory?.Name[hotel.DefaultLanguageCode]}
                rename={true}
              />
            )}
          </div>
          <div className="text-medium leading-none">
            {formatCurrency(averagePerNight)} per night
          </div>
          <div className="flex flex-col gap-2 no-mobile:flex-row text-medium leading-none text-green-600">
            <span>{parseInt("0" + competitorPercent)}% Direct Booking Discount</span>
            {rateDiscount !== "" && (
              <>
                <span className="hidden no-mobile:block">+</span>
                <span>{rateDiscount}</span>
              </>
            )}
          </div>
          <p className="text-black opacity-50 text-medium">
            {roomCategory?.Description[hotel.DefaultLanguageCode]}
          </p>
          <div className="flex items-center justify-between gap-2 w-full">
            <div>Number of travellers</div>
            <div className="flex justify-end gap-2">
              {Array(normalBedCount)
                .fill(null)
                .map((value, index) => (
                  <div key={index}>
                    <input
                      type="radio"
                      id={`adultCount_${number}_${index}`}
                      name={`adultCount_${number}`}
                      checked={reservation.AdultCount === index + 1}
                      onChange={() => {
                        updateAdultCount(index + 1);
                      }}
                      className="hidden peer"
                    />

                    <label
                      key={index}
                      htmlFor={`adultCount_${number}_${index}`}
                      className="cursor-pointer flex justify-center items-center block border-2 border-button text-button rounded-md h-10 w-10 leading-none peer-checked:bg-button peer-checked:text-white"
                    >
                      <div>{index + 1}</div>
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
