import DateRange from "../components/Calendar/DateRange";
import { motion } from "framer-motion";

function Calendar() {

  return (
    <motion.div
      initial={{opacity: 0, y: "-12px"}}
      animate={{opacity:1, y: "0px"}}
      exit={{opacity: 0, y: "-12px"}}
      className="absolute inset-0 overflow-y-auto w-full px-4 py-2 pb-4"
    >
      <DateRange />
    </motion.div>
  );
}

export default Calendar;
