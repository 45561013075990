import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";
import SelectHotel from "./scenes/SelectHotel";
import Calendar from "./scenes/Calendar";
import Accommodation from "./scenes/Accommodation";
import Extras from "./scenes/Extras";
import Summary from "./scenes/Summary";
import Details from "./scenes/Details";
import Payment from "./scenes/Payment";

import { useAppSelector, useAppDispatch } from "./app/hooks";
import {
  getBookingReservationPrice,
  selectBooking,
  setBookingPeopleCount,
  setBookingPrices,
} from "./features/booking/bookingSlice";
import { IBookingPrice } from "./features/booking/bookingInterface";
import { LoaderOverlay } from "./components/LoaderOverlay";
import { findProduct, getPriceByRate } from "./helpers/reservation";
import { selectHotel, getRoomAvailability } from "./features/hotel/hotelSlice";
import Success from "./scenes/Success";
import PageNotFound from "./scenes/PageNotFound";
import { AnimatePresence } from "framer-motion";
import Navbar from "./components/Navbar";
import Cart from "./components/Cart";
import Next from "./components/Next";
import RedirectHotel from "./scenes/RedirectHotel";
import RedirectPayment from "./scenes/RedirectPayment";
import { refreshReservations } from "./helpers/roomCategory";
import {
  GTMTrackingAddToCart,
  GTMTrackingBeginCheckout,
  GTMTrackingSelectAccommodation,
  GTMTrackingSelectPage,
} from "./helpers/GTMTracking";
import CalendarTop from "./components/Calendar/CalendarTop";

function App() {
  const dispatch = useAppDispatch();
  const hotel = useAppSelector(selectHotel);
  const booking = useAppSelector(selectBooking);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    refreshReservations();
  }, [
    hotel.RoomCategoryAvailabilities,
    booking.Rooms,
    booking.Products,
    booking.VoucherCode,
    booking.RateGroupId,
  ]);

  useEffect(() => {
    if (booking.StartUtc && booking.EndUtc) {
      dispatch(
        getRoomAvailability({
          StartUtc: booking.StartUtc,
          EndUtc: booking.EndUtc,
          VoucherCode: booking.VoucherCode,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.VoucherCode]);

  // Set total person count and prices
  useEffect(() => {
    if (booking.Reservations.length > 0 && location.pathname !== "/") {
      // Set people count
      const count = booking.Reservations.reduce((acc, curr) => {
        return (acc += curr.AdultCount);
      }, 0);

      const competitorPercent = hotel.CompetitorPriceRelativeAdjustment * 100 - 100;

      const prices: IBookingPrice[] = [];

      // Get max price
      // prices.push({
      //   CurrencyCode: hotel.DefaultCurrencyCode,
      //   Name: `${dateToMonthSmall(booking.StartUtc)} - ${dateToMonthSmall(booking.EndUtc)}`,
      //   NameSmall: "",
      //   Price: null,
      //   Class: "c-cart__booking-info-item-date",
      //   ClassPrice: "",
      // });

      // Combine the same reservations
      let reservations: any = [];
      booking.Reservations.forEach((reservation, i) => {
        const found = reservations.some((r: any) => {
          return (
            reservation.RoomCategoryId === r.RoomCategoryId &&
            reservation.AdultCount === r.AdultCount
          );
        });
        if (!found) {
          let array: any = { ...reservation };
          array.number = i;
          array.count = 1;
          reservations.push(array);
        } else {
          const array = reservations.find((r: any) => {
            return (
              reservation.RoomCategoryId === r.RoomCategoryId &&
              reservation.AdultCount === r.AdultCount
            );
          });
          array.count++;
        }
      });

      // Add reservations
      let priceAccommodation = 0;

      reservations.forEach((reservation: any) => {
        const pricing = getPriceByRate(reservation);
        const rate = hotel.Rates.find((r) => r.Id === reservation.RateId);

        let rateDiscount = "";
        if (pricing) {
          // prices.push({
          //   CurrencyCode: hotel.DefaultCurrencyCode,
          //   Name: `${reservation.count} x ${roomCategoryName}`,
          //   NameSmall: `(${formatCurrency(
          //     pricing.Price.AveragePerNight[hotel.DefaultCurrencyCode] * reservation.count
          //   )} pn)`,
          //   Price: pricing?.Price.Total[hotel.DefaultCurrencyCode] * reservation.count,
          //   Class: "c-cart__booking-info-item-category",
          //   ClassPrice: "",
          // });

          priceAccommodation += pricing?.Price.Total[hotel.DefaultCurrencyCode] * reservation.count;

          if (
            rate &&
            pricing.MaxPrice.Total[hotel.DefaultCurrencyCode] >
              pricing.Price.Total[hotel.DefaultCurrencyCode]
          ) {
            rateDiscount = `${rate.Name[hotel.DefaultLanguageCode]}`;
          }
        }

        // prices.push({
        //   CurrencyCode: hotel.DefaultCurrencyCode,
        //   Name: `${booking.NightCount} night${booking.NightCount === 1 ? "" : "s"}, ${
        //     reservation.AdultCount
        //   } traveller${reservation.AdultCount === 1 ? "" : "s"}`,
        //   NameSmall: "",
        //   Price: null,
        //   Class: "",
        //   ClassPrice: "",
        // });

        prices.push({
          CurrencyCode: hotel.DefaultCurrencyCode,
          Name: `${parseInt("0" + competitorPercent)}% Direct Booking Discount`,
          NameSmall: "",
          Price: null,
          Class: "c-cart__booking-info-item-discount",
          ClassPrice: "",
        });

        if (rateDiscount !== "") {
          prices.push({
            CurrencyCode: hotel.DefaultCurrencyCode,
            Name: `+ ${rateDiscount}`,
            NameSmall: "",
            Price: null,
            Class: "c-cart__booking-info-item-discount",
            ClassPrice: "",
          });
        }
      });

      // Get extras
      let priceExtras = 0;
      booking.Products.forEach((p) => {
        const product = findProduct(p.Id);
        if (product) {
          // prices.push({
          //   CurrencyCode: hotel.DefaultCurrencyCode,
          //   Name: `${product.Name[hotel.DefaultLanguageCode]}`,
          //   NameSmall: `(${formatCurrency(product.Prices[hotel.DefaultCurrencyCode])} pppn)`,
          //   Price:
          //     product.Prices[hotel.DefaultCurrencyCode] * booking.PeopleCount * booking.NightCount,
          //   Class: "c-cart__booking-info-item-product",
          //   ClassPrice: "",
          // });

          priceExtras +=
            product.Prices[hotel.DefaultCurrencyCode] * booking.PeopleCount * booking.NightCount;

          // prices.push({
          //   CurrencyCode: hotel.DefaultCurrencyCode,
          //   Name: `${booking.NightCount} night${booking.NightCount === 1 ? "" : "s"}, ${
          //     booking.PeopleCount
          //   } traveller${booking.PeopleCount === 1 ? "" : "s"}`,
          //   NameSmall: "",
          //   Price: null,
          //   Class: "",
          //   ClassPrice: "",
          // });
        }
      });

      // Dispatch all
      dispatch(setBookingPeopleCount(count));
      dispatch(
        setBookingPrices({
          summary: prices,
          accommodation: priceAccommodation,
          extras: priceExtras,
        })
      );
      dispatch(getBookingReservationPrice());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.Reservations, booking.NightCount, booking.PeopleCount]);

  // useEffect(() => {
  //   if (didMountRef.current && location.pathname !== "/payment") {
  //     dispatch(clearBookingPayment());
  //   }
  //   didMountRef.current = true;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [booking.Reservations, dispatch]);

  const next = () => {
    // GTM DATALAYER
    GTMTrackingSelectPage(location.pathname);

    if (location.pathname === "/calendar") {
      // GTM DATALAYER
      GTMTrackingSelectAccommodation();

      navigate("/accommodation");
    } else if (location.pathname === "/accommodation") {

      /**
       * Check if there are any extras / product
       */
      if (hotel.Products.length === 0) {
        navigate("/summary");
      } else {
        navigate("/extras");
      }
    } else if (location.pathname === "/extras") {
      // GTM DATALAYER
      GTMTrackingAddToCart();

      navigate("/summary");
    } else if (location.pathname === "/summary") {
      // GTM DATALAYER
      GTMTrackingBeginCheckout();

      navigate("/details");
    }
  };

  return (
    <div className={`app flex flex-col h-screen font-cityhub bg-booking`}>
      {["/calendar", "/accommodation", "/extras", "/summary", "/details", "/payment"].includes(
        location.pathname
      ) && <Navbar />}
      {["/calendar"].includes(location.pathname) && <CalendarTop />}
      <div className="flex flex-grow relative">
        <AnimatePresence initial={false}>
          <Routes location={location} key={location.pathname}>
            <Route index element={<SelectHotel />} />
            <Route
              path="calendar"
              element={
                hotel.RoomCategories.length === 0 ? <Navigate replace to="/" /> : <Calendar />
              }
            />
            <Route
              path="accommodation"
              element={
                booking.Rooms && booking.Rooms.length === 0 ? (
                  <Navigate replace to="/calendar" />
                ) : (
                  <Accommodation />
                )
              }
            />
            <Route
              path="extras"
              element={
                booking.Rooms && booking.Rooms.length === 0 ? (
                  <Navigate replace to="/calendar" />
                ) : (
                  <Extras />
                )
              }
            />
            <Route
              path="summary"
              element={
                booking.Rooms && booking.Rooms.length === 0 ? (
                  <Navigate replace to="/calendar" />
                ) : (
                  <Summary />
                )
              }
            />
            <Route
              path="details"
              element={
                booking.Rooms && booking.Rooms.length === 0 ? (
                  <Navigate replace to="/calendar" />
                ) : (
                  <Details />
                )
              }
            />
            <Route
              path="payment"
              element={
                booking.Rooms && booking.Rooms.length === 0 ? (
                  <Navigate replace to="/calendar" />
                ) : (
                  <Payment />
                )
              }
            />
            <Route path="success" element={<Success />} />
            <Route path="redirect-payment" element={<RedirectPayment />} />
            <Route path="amsterdam" element={<RedirectHotel hotelCode="AMS" />} />
            <Route path="rotterdam" element={<RedirectHotel hotelCode="RTM" />} />
            <Route path="copenhagen" element={<RedirectHotel hotelCode="CPH" />} />
            <Route path="reykjavik" element={<RedirectHotel hotelCode="RKV" />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </AnimatePresence>
      </div>

      {["/calendar", "/accommodation", "/extras"].includes(location.pathname) && (
        <Cart next={next} />
      )}

      {["/summary"].includes(location.pathname) && <Next next={next} />}

      <LoaderOverlay />
    </div>
  );
}

export default App;
