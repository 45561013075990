import { useAppDispatch, useAppSelector } from "../app/hooks";
import { clearBooking, clearBookingPayment, selectBooking } from "../features/booking/bookingSlice";
import { selectHotel } from "../features/hotel/hotelSlice";
import QRCode from "react-qr-code";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { getCurrentHost } from "../features/hotel/hotelApi";

function Success() {
  const dispatch = useAppDispatch();
  const hotel = useAppSelector(selectHotel);
  const booking = useAppSelector(selectBooking);
  const [host, setHost] = useState<{ [key: string]: string } |   null>(null);

  useEffect(() => {
    // Clear rooms, reservations and payment on success
    dispatch(clearBooking());
    dispatch(clearBookingPayment());

    // Get current host
    const currentHost = async () => {
      return await getCurrentHost(hotel.HotelCode);
    };
    currentHost().then((response) => {
      setHost(response.data.result);
    }).catch((error) => {
      // console.log(error);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: "-12px" }}
        animate={{ opacity: 1, y: "0px" }}
        exit={{ opacity: 0, y: "-12px" }}
        className="absolute inset-0 overflow-y-auto w-full px-4 py-2 pb-4"
      >
        <div className="flex flex-col items-center space-y-4 h-full text-medium">
          <div className="text-center">
            <div className="text-h3">Thank you</div>
            {hotel.Name !== undefined && (
              <div className="text-gray-500">
                We’ll see you soon in {hotel.Name[hotel.DefaultLanguageCode]}!
              </div>
            )}

            <h4 className="mt-4 text-h4">
              A confirmation mail has been sent to {booking.Customer?.Email}
            </h4>
          </div>

          <div className="flex-grow flex flex-col items-center justify-center">
            <div className="bg-white rounded-md p-4 w-48 h-48 flex items-center justify-center">
              <QRCode value={booking.Payment.QrCode} className="w-full" />
            </div>
            <div className="text-small mt-4">Use this QR code to check in to the hotel.</div>
          </div>

          <div className="flex flex-col space-y-2">
            {host !== null && (
              <div className="flex flex-col items-center">
                <div className="text-h4">Hi 👋, I'm {host.FirstName} your CityHost.</div>
                {host.Picture && host.FirstName && (
                  <div className="mt-2">
                    <img
                      src={host.Picture}
                      alt={host.FirstName}
                      className="w-20 h-20 rounded-full border-4 border-stone-300"
                    />
                  </div>
                )}
              </div>
            )}
            <div className="flex flex-col items-center gap-2 pb-4">
              <div className="text-medium text-center">
                Download the app to start making plans with your CityHost.
              </div>

              <div className="flex justify-center gap-2">
                <a
                  href="https://play.google.com/store/apps/details?id=xxx.festina.cityhub&hl=en"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src="/assets/images/play-store.svg" alt="play-store" />
                </a>

                <a
                  href="https://itunes.apple.com/us/app/cityhub-your-key-to-the-city/id1161402049?mt=8"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src="/assets/images/app-store.svg" alt="app-store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Success;
