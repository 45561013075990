import { store } from "../app/store";
import { IHotelRate } from "../features/hotel/hotelInterface";

/**
 * Find rate from Rates
 * @param Rates
 * @param Id
 * @returns IHotelRate | undefined
 */
export const findRate = (Id: IHotelRate["Id"]): IHotelRate | undefined => {
  const { hotel } = store.getState();
  return hotel.Rates.find((r) => r.Id === Id);
};

export const findRateByGroup = (RateGroupId: IHotelRate["RateGroupId"]): IHotelRate | undefined => {
  const { hotel } = store.getState();
  return hotel.Rates.find((r) => r.RateGroupId === RateGroupId);
};
